import ApiService from "./ApiService";

export async function getApi(payload) {
  return ApiService.fetchData({
    url:
      process.env.REACT_APP_API_URL +
      `/translation?search=${payload.search ?? ""}&length=${
        payload.length
      }&start=${payload.start}`,
    method: "get",
  });
}

export async function postApi(data) {
  return ApiService.fetchData({
    url: process.env.REACT_APP_API_URL + "/translation",
    method: "post",
    data,
  });
}

// export async function patchApi(data) {
//   return ApiService.fetchData({
//     url: process.env.REACT_APP_API_URL + "/video",
//     method: "patch",
//     data,
//   })
// }

export async function postUpdateApi(data) {
  return ApiService.fetchData({
    url: process.env.REACT_APP_API_URL + "/translation_update",
    method: "post",
    data,
  });
}

export async function deleteApi(data) {
  return ApiService.fetchData({
    url: process.env.REACT_APP_API_URL + `/translation/${data}`,
    method: "delete",
    data,
  });
}

export async function getNotificationApi() {
  return ApiService.fetchData({
    url: process.env.REACT_APP_API_URL + `/translation_notification`,
    method: "get",
  });
}

export async function getPurchaseNotificationApi() {
  return ApiService.fetchData({
    url: process.env.REACT_APP_API_URL + `/purchase_listing_notification`,
    method: "get",
  });
}
