import React, { memo, useMemo, lazy, Suspense } from "react"
import { Loading } from "components/shared"
import { useSelector } from "react-redux"
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from "constants/theme.constant"
import useAuth from "utils/hooks/useAuth"
import useDirection from "utils/hooks/useDirection"
import useLocale from "utils/hooks/useLocale"
import { PDFViewer } from "@react-pdf/renderer"
import CommissionSub from "components/pdf/commission.sub"
import SalesQuo from "components/pdf/sales.quo"
import InsuranceQuo from "components/pdf/insurance.quo"
import BankCheckList from "components/pdf/bank.check.list"
import EHak from "components/pdf/e.hak"
import SalesInv from "components/pdf/sales.inv"
import JPJK1 from "components/pdf/jpj.k1"
import FDSDisbusement from "components/pdf/fds.disbusement"
import DOForm from "components/pdf/doForm"
import VDForm2 from "components/pdf/vd.form.2"
import VsoForm from "components/pdf/vsoForm"
import VDForm1 from "components/pdf/vd.form.1"

const layouts = {
  [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
}

const Layout = () => {
  const layoutType = useSelector((state) => state.theme.layout.type)

  const { authenticated } = useAuth()

  useDirection()

  useLocale()

  const AppLayout = useMemo(() => {
    if (authenticated) {
      return layouts[layoutType]
    }
    return lazy(() => import("./AuthLayout"))
  }, [layoutType, authenticated])


  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      }
    >
      <AppLayout />
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <VDForm2 data={{title: "K8"}} />
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <DOForm data={doData} custData={details} />
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <FDSDisbusement data={otherData} custData={details} />
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <JPJK1 data={jpjData} custData={details} />
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <SalesInv data={salesInvData} custData={details} />
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <EHak custData={details} />
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <BankCheckList  />
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <InsuranceQuo data={insuranceData} custData={details}/>
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <SalesQuo  data={{ ap_id: 1, date: new Date(),
        total_car_price: "",
        car_price_with_cif: "",
        uob_rate: "",
        payable_custom_duty: "",
        misc: "",
        service_charge: "",
        total_cost_price: "",
        total_other_charges: "",
        jpj_registration_fees_company: 550.00,
        jpj_registration_fees_private: 350.00,
        inspection_and_handling_fees: 2250.00,
        one_year_road_tax: "",
        kastam_clearance: "",
        one_year_insurance: "",
        other_charges: [{
          label: "JPJ Registration Fees (Company)",
          detail: 550, 
          defaultValue: true
        },{
          label: "JPJ Registration Fees (Private)",
          detail: 350,
          defaultValue: true
        },{
          label: "Inspection & Handling Fees",
          detail: 2250, 
          defaultValue: true
        },{
          label: "One Year Road Tax",
          detail: "", 
          defaultValue: true
        },{
          label: "KASTAM Clearance",
          detail: "", 
          defaultValue: true
        },{
          label: "One Year Insurance",
          detail: "", 
          defaultValue: true
        }],
        total_payment: [{label:""}],
        balance: "",
        loan_amount: ""}}/>
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <CommissionSub data={commissionData} custData={details} quoData={salesQuoData} />
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <VsoForm data={vsoData} custData={details}/>
      </PDFViewer> */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <VDForm1 data={{
                    title: "K1",
                  }}/>
      </PDFViewer> */}
    </Suspense>
  )
}

export default memo(Layout)
