import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import InsuranceTable from "./components/insurance.table";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Calibri-Bold",
//   src: require("./fonts/Calibri-Bold.TTF"),
// });

// Font.register({
//   family: "Calibri",
//   src: require("./fonts/Calibri Light.ttf"),
// });

// Font.register({
//   family: "Calibri-Regular",
//   src: require("./fonts/Calibri-Regular.ttf"),
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  mainView: {
    paddingTop: 60,
    paddingLeft: 60,
    paddingRight: 60,
  },
  twoColumnBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  columnText: {
    alignItems: "end",
    fontSize: 13.87,
    color: "black",
    // fontFamily: "Calibri",
  },
  docColumnTitleBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    borderBottom: "none",
    backgroundColor: "#404040",
  },
  docColumnPrivate: {
    alignItems: "end",
    fontSize: 13.87,
    color: "white",
    // fontFamily: "Calibri-Bold",
  },
  docColumnCompany: {
    alignSelf: "center",
    alignItems: "end",
    fontSize: 13.87,
    color: "white",
    // fontFamily: "Calibri-Bold",
  },
  bottomFirstText: {
    alignItems: "end",
    fontSize: 12.84,
    color: "red",
    // fontFamily: "Calibri-Regular",
  },
  bottomSecondText: {
    alignItems: "end",
    fontSize: 12.84,
    color: "red",
    marginTop: 15,
    // fontFamily: "Calibri-Regular",
  },
});

const InsuranceQuo = ({ data }) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.mainView}>
          <Text
            style={{
              fontSize: 20,
              textDecoration: "underline",
              // fontFamily: "Calibri-Bold",
              marginBottom: 35,
            }}
          >
            INSURANCE PURPOSE
          </Text>
          <InsuranceTable data={data} />
          <Text
            style={{
              fontSize: 13.87,
              // fontFamily: "Calibri",
              marginTop: 20,
              alignSelf: "center",
            }}
          >
            DOCUMENT FOR APPLYING QUOTATION
          </Text>
          <div>
            <View style={styles.docColumnTitleBorder}>
              <View
                style={{ flex: 5, borderRight: "1px solid black", padding: 1 }}
              >
                <Text style={styles.docColumnPrivate}>PRIVATE</Text>
              </View>
              <View style={{ flex: 7, padding: 1 }}>
                <Text style={styles.docColumnCompany}>COMPANY</Text>
              </View>
            </View>

            <View style={styles.twoColumnBorder}>
              <View
                style={{ flex: 5, borderRight: "1px solid black", padding: 1 }}
              >
                <Text style={styles.columnText}>IC / LICENSE/VSO/PK/</Text>
                <Text style={styles.columnText}>NCD LETTER (IF HAVE)</Text>
              </View>
              <View style={{ flex: 7, padding: 1 }}>
                <Text style={styles.columnText}>COMPANY DOCUMENT / VSO/PK</Text>
                <Text style={styles.columnText}>NCD LETTER (IF HAVE)</Text>
              </View>
            </View>
          </div>

          <div>
            <Text style={styles.bottomSecondText}>NOTE</Text>
            <Text style={styles.bottomFirstText}>
              * APPLY QUOTATION FOR INSURANCE WITHIN 1 OR 2 WEEKS BEFORE THE
            </Text>
            <Text style={styles.bottomFirstText}>
              {" "}
              &nbsp;REGISTER DATE (VALIDATION FOR QUOTATION IS 14DAYS)
            </Text>
            <Text style={styles.bottomSecondText}>
              * PURCHASE COVER NOTE ONLY APPLY 1 OR 2DAYS BEFORE THE
            </Text>
            <Text style={styles.bottomFirstText}>
              {" "}
              &nbsp;REGISTRATION (VALIDATION OF COVER NOTE IS 4DAYS)
            </Text>
          </div>
        </View>
      </Page>
    </Document>
  );
};

export default InsuranceQuo;
