import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
  Tspan,
} from "@react-pdf/renderer";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Inter",
//   src: require("../fonts/Inter.ttf"),
// });

// Font.register({
//   family: "Inter-Bold",
//   src: require("../fonts/Inter-Bold.ttf"),
// });

// Font.register({
//   family: "Inter-SemiBold",
//   src: require("../fonts/Inter-SemiBold.ttf"),
// });

// Font.register({
//   family: "SimSun-Bold",
//   src: require("../fonts/SIMSUN.ttf"),
// });

const styles = StyleSheet.create({
  red_line: {
    width: "100%",
    backgroundColor: "red",
    padding: 3,
  },

  firstContainer: {
    display: "flex",
    marginTop: 15,
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  firstSubContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
  },
  firstContainerText: {
    marginLeft: 2,
    fontSize: 8,
    fontWeight: 100,
  },

  commonRedContainer: {
    display: "flex",
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    backgroundColor: "#CC0000",
    borderBottom: "none",
  },

  commonGreenContainer: {
    display: "flex",
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    backgroundColor: "#92D050",
    borderBottom: "none",
  },

  commonYellowContainer: {
    display: "flex",
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    backgroundColor: "#FFC000",
    borderBottom: "none",
  },

  commonBlueContainer: {
    display: "flex",
    // marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
    // border: "1px solid black",
    // backgroundColor: "#538DD5",
    // borderBottom: "none",
  },

  noMarginBlueContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
  },

  quoContainerBlackTitle: {
    fontSize: 14,
    color: "black",
    textTransform: "uppercase",
    // fontFamily: "SimSun-Bold",
    alignSelf: "center",
  },

  nullBlueContainer: {
    display: "flex",
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "center",
    // backgroundColor: "#538DD5",
  },

  commonWhiteContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    borderTop: "none",
  },

  commonContainerWhiteTitle: {
    fontSize: 8,
    color: "white",
    textTransform: "uppercase",
    // fontFamily: "Inter-Bold",
  },

  commonContainerBlackTitle: {
    fontSize: 8,
    color: "black",
    textTransform: "uppercase",
    // fontFamily: "Inter-SemiBold",
  },

  leftColumnText: {
    alignItems: "flex-end",
    fontSize: 11,
    color: "white",
    // fontFamily: "Calibri-Regular",
  },
  leftSecondColumnText: {
    alignItems: "flex-end",
    fontSize: 11,
    color: "black",
    // fontFamily: "Calibri",
  },
});

const HeaderCommission = ({ data, title1, title2, title3, title4, type }) => {
  const data_shown_name = data?.ap_detail?.shown_name;
  const renderBackgroundColor = () => {
    switch (data_shown_name) {
      case "DCF":
        return "#D22B2B";
      case "DCFP":
        return "#FFBF00";
      case "DCK":
        return "#538DD5";
      case "DCGS":
        return "#0D6856";
      case "DCP":
        return "#c3a372";
      default:
        return "#D22B2B";
    }
  };
  return (
    <>
      {type == "twoColumns" ? (
        <View style={styles.commonRedContainer}>
          <View style={{ flex: 10, padding: 0.5 }}>
            <Text style={styles.commonContainerWhiteTitle}>{title1}</Text>
          </View>
          <View style={{ flex: 2, padding: 0.5 }}>
            <Text
              style={{
                fontSize: 8,
                color: "white",
                textTransform: "uppercase",
                // fontFamily: "Inter-Bold",
                marginLeft: 5,
              }}
            >
              {title2}
            </Text>
          </View>
        </View>
      ) : type == "twoCenterColumns" ? (
        <View
          style={[
            styles.commonBlueContainer,
            { backgroundColor: renderBackgroundColor() },
          ]}
        >
          <View style={{ flex: 9, padding: 0.5 }}>
            <Text
              style={{
                fontSize: 8,
                color: "white",
                textTransform: "uppercase",
                // fontFamily: "Inter-Bold",
                alignSelf: "center",
              }}
            >
              {title1}
            </Text>
          </View>
          <View style={{ flex: 3, padding: 0.5 }}>
            <Text
              style={{
                fontSize: 8,
                color: "white",
                textTransform: "uppercase",
                // fontFamily: "Inter-Bold",
                alignSelf: "center",
                marginLeft: 2,
              }}
            >
              {title2}
            </Text>
          </View>
        </View>
      ) : type == "fourColumns" || type == "fourBlueColumns" ? (
        <View
          style={
            type == "fourBlueColumns"
              ? [
                  styles.commonBlueContainer,
                  { backgroundColor: renderBackgroundColor() },
                ]
              : styles.commonGreenContainer
          }
        >
          <View style={{ flex: 3, padding: 0.5 }}>
            <Text
              style={
                type == "fourBlueColumns"
                  ? styles.commonContainerWhiteTitle
                  : styles.commonContainerBlackTitle
              }
            >
              {title1}
            </Text>
          </View>
          <View style={{ flex: 3, padding: 0.5 }}>
            <Text
              style={
                type == "fourBlueColumns"
                  ? styles.commonContainerWhiteTitle
                  : styles.commonContainerBlackTitle
              }
            >
              {title2}
            </Text>
          </View>
          <View style={{ flex: 2, padding: 0.5 }}>
            <Text
              style={
                type == "fourBlueColumns"
                  ? styles.commonContainerWhiteTitle
                  : styles.commonContainerBlackTitle
              }
            >
              {title3}
            </Text>
          </View>
          <View style={{ flex: 4, padding: 0.5 }}>
            <Text
              style={
                type == "fourBlueColumns"
                  ? {
                      fontSize: 8,
                      color: "white",
                      textTransform: "uppercase",
                      // fontFamily: "Inter-SemiBold",
                      marginLeft: 20,
                    }
                  : {
                      fontSize: 8,
                      color: "black",
                      textTransform: "uppercase",
                      // fontFamily: "Inter-SemiBold",
                      marginLeft: 20,
                    }
              }
            >
              {title4}
            </Text>
          </View>
        </View>
      ) : type == "nullBlueColumns" ? (
        <View
          style={[
            styles.nullBlueContainer,
            { backgroundColor: renderBackgroundColor() },
          ]}
        >
          <View style={{ flex: 12, padding: 5 }}>
            <Text style={styles.commonContainerWhiteTitle}>{title1}</Text>
          </View>
        </View>
      ) : type == "twoLeftColumns" ? (
        <>
          <View
            style={[
              styles.commonBlueContainer,
              { backgroundColor: renderBackgroundColor() },
            ]}
          >
            <View
              style={{ flex: 4, padding: 1, borderRight: "1px solid black" }}
            >
              <Text style={styles.leftColumnText}>{title1}</Text>
            </View>
            <View style={{ flex: 4, padding: 1 }}>
              <Text style={styles.leftColumnText}>{title2}</Text>
            </View>
          </View>
          <View style={styles.commonWhiteContainer}>
            <View
              style={{ flex: 4, padding: 15, borderRight: "1px solid black" }}
            >
              <Text style={styles.leftColumnText}>null</Text>
            </View>
            <View style={{ flex: 4, padding: 15 }}>
              <Text style={styles.leftColumnText}>null</Text>
            </View>
          </View>
          <View style={styles.commonWhiteContainer}>
            <View
              style={{ flex: 4, padding: 1, borderRight: "1px solid black" }}
            >
              <Text style={styles.leftSecondColumnText}>Name : {title3}</Text>
              <Text style={styles.leftSecondColumnText}>
                Date : {new Date().toLocaleDateString()}
              </Text>
            </View>
            <View style={{ flex: 4, padding: 1 }}>
              <Text style={styles.leftSecondColumnText}>Name : </Text>
              <Text style={styles.leftSecondColumnText}>Date : </Text>
            </View>
          </View>
        </>
      ) : type == "quoHeader" ? (
        <View
          style={[
            styles.noMarginBlueContainer,
            { backgroundColor: renderBackgroundColor() },
          ]}
        >
          <View style={{ flex: 12 }}>
            <Text style={[styles.quoContainerBlackTitle]}>{title1}</Text>
          </View>
        </View>
      ) : (
        <View
          style={
            type == "oneBlueColumns"
              ? [
                  styles.commonBlueContainer,
                  { backgroundColor: renderBackgroundColor() },
                ]
              : styles.commonYellowContainer
          }
        >
          <View style={{ flex: 12, padding: 0.5 }}>
            <Text
              style={
                type == "oneBlueColumns"
                  ? styles.commonContainerWhiteTitle
                  : styles.commonContainerBlackTitle
              }
            >
              {title1}
            </Text>
          </View>
        </View>
      )}
    </>
  );
};

export default HeaderCommission;
