import { View, Text, StyleSheet, Font, Image } from "@react-pdf/renderer";
import Table from "../component/table";
import TickIcon from "../../../assets/images/tick_icon_do.png";

Font.register({
  family: "Times",
  src: require("../fonts/times.ttf"),
});
Font.register({
  family: "Seguisym",
  src: require("../fonts/seguisym.ttf"),
});

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    // border: "1px solid black",
    // borderBottom: "none",
    // borderTop: "none",
  },
  fiveColumnBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  fiveColumnText: {
    alignSelf: "center",
    fontSize: 8.76,
    color: "black",
    // fontFamily: "Times",
  },
  symbolText: {
    alignSelf: "center",
    fontSize: 7.39,
    color: "black",
    // fontFamily: "Seguisym",
  },
});
const DoTable = ({ data }) => {
  const items = [
    { col2: "SPARE KEY", key: "spare_key" },
    { col2: "FLOOR MAT", key: "floor_mat" },
    { col2: "TOWING COVER", key: "towing_cover" },
    { col2: "ANTENA (CONTINENTAL )", key: "antena" },
    { col2: "CIGARETTE LIGHTER", key: "ciga_retta_lighter" },
    { col2: "DARK GLASS", key: "dark_glass" },
    { col2: "SPARE TYRE", key: "spare_tyre" },
    { col2: "TOOL KITS", key: "tool_kits" },
    { col2: "JACK", key: "jack" },
    { col2: "CREDIT NOTE", key: "credit_note" },
    { col2: "WARRANTY BOOKLET", key: "warranty_booklet" },
    { col2: "COPY DOCUMENT OF PK SET", key: "copy_document" },
    { col2: "INSURANCE COVER NOTE", key: "insurance_cover" },
    { col2: "ORIGINAL ROAD TAX", key: "original_road_tax" },
    { col2: "ORIGINAL REGISTRATION CARD", key: "original_registration_card" },
    { col2: "TYRE REPAIR KIT", key: "tyre_repair_kit" },
  ];

  const renderTableRows = () => {
    const filledRows = items
      .filter((item) => data?.do?.[item.key] === "yes")
      .map((item, index) => (
        <Table
          key={item.key}
          col1={index + 1}
          col2={item.col2}
          col3={<Image src={TickIcon} />}
          col4={null}
        />
      ));

    const emptyRows = Array(16 - filledRows.length)
      .fill(null)
      .map((_, index) => (
        <Table
          key={`empty-${index}`}
          col1={filledRows.length + index + 1}
          col2={null}
          col3={null}
          col4={null}
        />
      ));

    return [...filledRows, ...emptyRows];
  };

  return (
    <div>
      <View style={styles.row}>
        <View style={{ flex: 7, borderBottom: "1px solid black" }}>
          <Table
            col1="No"
            col2="ITEM LIST (Whichever applicable)"
            col3={<Text style={styles.symbolText}>YES</Text>}
            col4={<Text style={styles.symbolText}>No</Text>}
          />
          {renderTableRows()}
        </View>
        <View style={{ flex: 5 }}>
          <View
            style={{
              borderRight: "1px solid black",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              padding: 1,
            }}
          >
            <Text style={styles.fiveColumnText}>Remark</Text>
          </View>
          <View
            style={{
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              height: 202,
            }}
          >
            <Text style={styles.fiveColumnText}>{data?.do?.remark}</Text>
          </View>
        </View>
      </View>
    </div>
  );

  // return (
  //   <div>
  //     <View style={styles.row}>
  //       <View style={{ flex: 7 }}>
  //         <Table
  //           col1="No"
  //           col2="ITEM LIST (Whichever applicable)"
  //           col3={<Text style={styles.symbolText}>YES</Text>}
  //           col4={<Text style={styles.symbolText}>No</Text>}
  //         />
  //         <Table
  //           col1={1}
  //           col2="SPARE KEY"
  //           col3={data?.do?.spare_key == "yes" && <Image src={TickIcon} />}
  //           col4={data?.do?.spare_key == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={2}
  //           col2="FLOOR MAT"
  //           col3={
  //             data?.do?.floor_mat == "yes" && (
  //               <Image width={10} src={TickIcon} />
  //             )
  //           }
  //           col4={data?.do?.floor_mat == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={3}
  //           col2="TOWING COVER"
  //           col3={data?.do?.towing_cover == "yes" && <Image src={TickIcon} />}
  //           col4={data?.do?.towing_cover == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={4}
  //           col2="ANTENA (CONTINENTAL )"
  //           col3={data?.do?.antena == "yes" && <Image src={TickIcon} />}
  //           col4={data?.do?.antena == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={5}
  //           col2="CIGARETTE LIGHTER"
  //           col3={
  //             data?.do?.ciga_retta_lighter == "yes" && <Image src={TickIcon} />
  //           }
  //           col4={
  //             data?.do?.ciga_retta_lighter == "no" && <Image src={TickIcon} />
  //           }
  //         />
  //         <Table
  //           col1={6}
  //           col2="DARK GLASS"
  //           col3={data?.do?.dark_glass == "yes" && <Image src={TickIcon} />}
  //           col4={data?.do?.dark_glass == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={7}
  //           col2="SPARE TYRE"
  //           col3={data?.do?.spare_tyre == "yes" && <Image src={TickIcon} />}
  //           col4={data?.do?.spare_tyre == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={8}
  //           col2="TOOL KITS"
  //           col3={data?.do?.tool_kits == "yes" && <Image src={TickIcon} />}
  //           col4={data?.do?.tool_kits == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={9}
  //           col2="JACK"
  //           col3={data?.do?.jack == "yes" && <Image src={TickIcon} />}
  //           col4={data?.do?.jack == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={10}
  //           col2="CREDIT NOTE"
  //           col3={data?.do?.credit_note == "yes" && <Image src={TickIcon} />}
  //           col4={data?.do?.credit_note == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={11}
  //           col2="WARRANTY BOOKLET"
  //           col3={
  //             data?.do?.warranty_booklet == "yes" && <Image src={TickIcon} />
  //           }
  //           col4={
  //             data?.do?.warranty_booklet == "no" && <Image src={TickIcon} />
  //           }
  //         />
  //         <Table
  //           col1={12}
  //           col2="COPY DOCUMENT OF PK SET"
  //           col3={data?.do?.copy_document == "yes" && <Image src={TickIcon} />}
  //           col4={data?.do?.copy_document == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={13}
  //           col2="INSURANCE COVER NOTE"
  //           col3={
  //             data?.do?.insurance_cover == "yes" && <Image src={TickIcon} />
  //           }
  //           col4={data?.do?.insurance_cover == "no" && <Image src={TickIcon} />}
  //         />
  //         <Table
  //           col1={14}
  //           col2="ORIGINAL ROAD TAX"
  //           col3={
  //             data?.do?.original_road_tax == "yes" && <Image src={TickIcon} />
  //           }
  //           col4={
  //             data?.do?.original_road_tax == "no" && <Image src={TickIcon} />
  //           }
  //         />
  //         <Table
  //           col1={15}
  //           col2="ORIGINAL REGISTRATION CARD"
  //           col3={
  //             data?.do?.original_registration_card == "yes" && (
  //               <Image src={TickIcon} />
  //             )
  //           }
  //           col4={
  //             data?.do?.original_registration_card == "no" && (
  //               <Image src={TickIcon} />
  //             )
  //           }
  //         />
  //         <View style={styles.fiveColumnBorder}>
  //           <View
  //             style={{ flex: 1, padding: 1, borderRight: "1px solid black" }}
  //           >
  //             <Text style={styles.fiveColumnText}>16</Text>
  //           </View>
  //           <View
  //             style={{ flex: 4, padding: 1, borderRight: "1px solid black" }}
  //           >
  //             <Text style={styles.fiveColumnText}>TYRE REPAIR KIT</Text>
  //           </View>
  //           <View
  //             style={{ flex: 1, padding: 1, borderRight: "1px solid black" }}
  //           >
  //             {data?.do?.tyre_repair_kit == "yes" && <Image src={TickIcon} />}
  //           </View>
  //           <View style={{ flex: 1, padding: 1 }}>
  //             {data?.do?.tyre_repair_kit !== "yes" && <Image src={TickIcon} />}
  //           </View>
  //         </View>
  //       </View>
  //       <View style={{ flex: 5 }}>
  //         <View
  //           style={{
  //             borderRight: "1px solid black",
  //             borderTop: "1px solid black",
  //             borderBottom: "1px solid black",
  //             padding: 1,
  //           }}
  //         >
  //           <Text style={styles.fiveColumnText}>Remark</Text>
  //         </View>
  //         <View
  //           style={{
  //             borderRight: "1px solid black",
  //             borderBottom: "1px solid black",
  //             height: 202,
  //           }}
  //         >
  //           <Text style={styles.fiveColumnText}>{data?.do?.remark}</Text>
  //         </View>
  //       </View>
  //     </View>
  //   </div>
  // );
};

export default DoTable;
