import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import TwoColumnTable from "./components/two.column.table";
import SalesInvTable from "./components/sales.inv.table";

// Font.register({
//   family: "Calibri-Bold",
//   src: require("./fonts/Calibri-Bold.TTF"),
// });

// Font.register({
//   family: "Calibri",
//   src: require("./fonts/Calibri Light.ttf"),
// });

// Font.register({
//   family: "Calibri-Regular",
//   src: require("./fonts/Calibri-Regular.ttf"),
// });

// Font.register({
//   family: "Cambria",
//   src: require("./fonts/Cambria.ttf"),
// });

// Font.register({
//   family: "Times",
//   src: require("./fonts/times.ttf"),
// });

// Font.register({
//   family: "Times-Bold",
//   src: require("./fonts/times-bold.ttf"),
// });

// Font.register({
//   family: "Seguisym",
//   src: require("./fonts/seguisym.ttf"),
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  mainView: {
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  header: {
    fontSize: 18,
    marginBottom: 20,
    textAlign: "left",
    // fontFamily: "Calibri-Bold",
  },
  topText: {
    fontSize: 11,
    // fontFamily: "Calibri-Regular",
  },
  topSecondText: {
    fontSize: 11,
    // fontFamily: "Calibri-Regular",
    marginBottom: 30,
  },
  twoColumnBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  invColumnText: {
    alignItems: "flex-end",
    fontSize: 14,
    color: "black",
    // fontFamily: "Calibri-Bold",
  },
  invSecondColumnText: {
    alignItems: "flex-end",
    fontSize: 17,
    marginLeft: 8,
    color: "black",
    // fontFamily: "Calibri-Regular",
  },
});

const SalesInv = ({ data }) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.mainView}>
          <Text style={styles.header}>
            FOR SALES INVOICE, Debit note / Credit Note
          </Text>
          <Text style={styles.topText}>
            Please prepare/ready all this documents before send to Cui Ling to
            get Sales Invoice,
          </Text>
          <Text style={styles.topSecondText}>
            Debit note / Credit note. And tick "√" for submit done :-
          </Text>

          <SalesInvTable data={data} />

          <View
            style={{
              marginTop: 70,
            }}
          >
            <TwoColumnTable
              column1="SALES PERSON :"
              column2={`${data?.sales_agent_name} @ ${data?.sales_agent_contact}`}
              type="customerInvList"
            />
            <TwoColumnTable
              column1="VEHICLE MODEL :"
              column2={`${data?.vehicle_model ? data.vehicle_model : ""} ${
                data?.vehicle_make ? `/ ${data.vehicle_make}` : ""
              }`}
              type="customerInvList"
            />
            <TwoColumnTable
              column1="CHASISS NO :"
              column2={data?.vehicle_chasis_no}
              type="customerInvList"
            />
            <TwoColumnTable
              column1="CUSTOMER NAME :"
              column2={data?.personal_name}
              type="customerInvList"
            />
            <TwoColumnTable
              column1="COMPANY NAME :"
              column2={data?.ap_detail?.name}
              type="customerInvList"
            />

            <View style={styles.twoColumnBorder}>
              <View
                style={{ flex: 5, borderRight: "1px solid black", padding: 1 }}
              >
                <Text style={styles.invColumnText}>SUBMISSION DATE : </Text>
              </View>
              <View style={{ flex: 7, padding: 1 }}>
                <Text style={styles.invSecondColumnText}>
                  {new Date().toLocaleDateString()}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default SalesInv;
