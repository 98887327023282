import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
  Tspan,
} from "@react-pdf/renderer";
import LogoImage from "./../../assets/images/logo-dark-full.png";
import HeaderCommission from "./components/header.commission";
import TotalCommission from "./components/total.commission";
import CommissionFirstTable from "./components/commission.first_table";
import CommissionSecondTable from "./components/commission.second_table";
import CommissionThirdTable from "./components/commission.third_table";
import { formatNumber } from "helper/return.number";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "SimSun-Bold",
//   src: require("./fonts/SIMSUN.ttf"),
// });

// Font.register({
//   family: "Inter",
//   src: require("./fonts/Inter.ttf"),
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 0,
    paddingHorizontal: 35,
  },
  mainView: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  header: {
    fontSize: 10,
    marginLeft: -15,
    marginBottom: 8,
    textDecoration: "underline",
    // fontFamily: "SimSun-Bold",
    textAlign: "left",
    color: "blue",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  red_line: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    width: "110%",
    backgroundColor: "#CC0000",
    padding: 3,
  },
  firstContainer: {
    display: "flex",
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  firstSubContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
  },
  firstContainerText: {
    fontSize: 8,
    fontWeight: 100,
    //transform: [{ scaleX: 30 }],
    // fontFamily: "Inter",
  },
  firstContainerSubText: {
    fontSize: 8,
    marginLeft: 2,
    fontWeight: 100,
    //transform: [{ scaleX: 30 }],
    // fontFamily: "Inter",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    // borderRight: "1px solid black",
    // borderLeft: "1px solid black",
  },
  grandRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    marginTop: 8,
  },
  balanceRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  bottomContainerText: {
    marginTop: 0,
    marginBottom: 3,
    marginLeft: 2,
    fontSize: 8,
    // fontFamily: "Inter",
  },
  bottomSecondText: {
    fontSize: 7,
    marginLeft: 2,
    marginTop: 1,
    // fontFamily: "Inter",
  },
  submitText: {
    fontSize: 8,
    // fontFamily: "Inter",
    marginTop: 30,
    marginBottom: 5,
  },
  dateText: {
    fontSize: 8,
    // fontFamily: "Inter",
    marginTop: 2,
  },

  firstColumnText: {
    fontSize: 8,
    marginLeft: 5,
    // fontFamily: "Inter",
  },
  rmColumnText: {
    fontSize: 7,
    marginLeft: 7,
    // fontFamily: "Inter",
  },
  priceColumnText: {
    fontSize: 8,
    marginRight: 5,
    // fontFamily: "Inter",
  },
  spanText: {
    fontSize: 8,
    color: "red",
    // fontFamily: "Inter",
  },
  commonContainerBlackTitle: {
    fontSize: 8,
    color: "black",
    textTransform: "uppercase",
    // fontFamily: "Inter-SemiBold",
  },
  commonYellowContainer: {
    display: "flex",
    // marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
    // border: "1px solid black",
    backgroundColor: "#FFC000",
    borderBottom: "none",
  },
});

const CommissionSub = ({ data }) => {
  const calculateCarPriceWithCIF = (uobRate, currency) => {
    if (uobRate && currency) {
      const total = Number(uobRate) * Number(currency);
      const formatTotal = Number(total).toFixed(2);

      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "-";
  };

  const calculateServiceCharge = () => {
    let A = data?.sales_quotation?.total_car_price
      ? data.sales_quotation.total_car_price
      : 0;
    let B = data?.service_charge ? data.service_charge : 0;
    const total = A * (B / 100);

    const formatTotal = Number(total).toFixed(2);

    if (total > 0) {
      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return " -";
  };

  const calculateTotalCharges = (data) => {
    let total = 0;
    data?.map((item, index) => {
      let price = item.detail ? Number(item.detail) : 0;
      total = total + price;
    });

    const formatTotal = Number(total).toFixed(2);
    if (total > 0) {
      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return " -";
  };

  const calGrandTotal = (totalCarPrice, totalCostPrice, totalOtherCharges) => {
    let A = totalCarPrice ? totalCarPrice : 0;
    let B = totalCostPrice ? totalCostPrice : 0;
    let C = totalOtherCharges ? totalOtherCharges : 0;
    const total = A + B + C;
    const formatTotal = Number(total).toFixed(2);

    if (total > 0) {
      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "-";
  };

  const calculateTotalPayment = (data) => {
    let total = 0;
    data?.map((item, index) => {
      let price = item.label ? Number(item.label) : 0;
      total = total + price;
    });

    const formatTotal = Number(total).toFixed(2);

    if (total > 0) {
      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return " -";
  };
  const calculateSupplierInvoice = (data) => {
    let total = 0;
    data?.map((item, index) => {
      let price = item.detail ? Number(item.detail) : 0;
      total = total + price;
    });

    const formatTotal = Number(total).toFixed(2);

    if (total > 0) {
      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return " -";
  };

  // const formatCash = (cash) => {
  //   if (cash) {
  //     if (cash < 0) {
  //       const number = Math.abs(cash);
  //       return `(${number.toLocaleString(undefined, {
  //         minimumFractionDigits: 2,
  //         maximumFractionDigits: 2,
  //       })})`;
  //     } else {
  //       const formatTotal = Number(cash).toFixed(2);
  //       // const formatTotal = formatNumber(cash).toFixed(2);

  //       return formatTotal.toLocaleString(undefined, {
  //         minimumFractionDigits: 2,
  //         maximumFractionDigits: 2,
  //       });
  //     }
  //   }
  //   return "-";
  // };

  const formatCash = (currency) => {
    let cash = currency ? Number(currency).toFixed(2) : 0;
    if (cash) {
      if (cash < 0) {
        const number = Math.abs(cash);
        return `(${number.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })})`;
      } else {
        return Number(cash).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    return "-";
  };

  return (
    <Document>
      <Page style={styles.body} pageLayout="twoColumnLeft">
        <View style={styles.mainView}>
          <Text style={styles.header}>FOR COMMISSION SUBMISSION</Text>
          <div style={styles.red_line}></div>

          <CommissionFirstTable data={data} />

          {/* <div>
            <HeaderCommission
              title1="description"
              title2="amount"
              type="twoColumns"
            />
            <View style={styles.descriptionContainer}>
              <View style={{ flex: 6, padding: 0.5 }}></View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 2,
                    textDecoration: "underline",
                    // fontFamily: "Inter-SemiBold",
                  }}
                >
                  UOB rate *
                </Text>
              </View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 2,
                    textDecoration: "underline",
                    color: "red",
                    // fontFamily: "Inter-SemiBold",
                  }}
                >
                  GBP/JPY
                </Text>
              </View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 2,
                    textDecoration: "underline",
                    textAlign: "center",
                    justifyContent: "center",
                    // fontFamily: "Inter-SemiBold",
                  }}
                >
                  RM
                </Text>
              </View>
            </View>
            <CommissionSecondTable data={data} />
          </div> */}

          <View
            style={[
              {
                marginTop: "10px",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
              },
            ]}
          >
            <HeaderCommission
              data={data}
              title1="description"
              title2="amount"
              type="twoCenterColumns"
            />
            <View style={styles.descriptionContainer}>
              <View style={{ flex: 6, padding: 0.5 }}></View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 2, // fontFamily: "Inter"
                  }}
                >
                  UOB rate *
                </Text>
              </View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 2,
                    fontFamily: "Inter-SemiBold",
                  }}
                >
                  GBP/JPY
                </Text>
              </View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 2,
                    textAlign: "center",
                    justifyContent: "center",
                    fontFamily: "Inter-SemiBold",
                  }}
                >
                  RM
                </Text>
              </View>
            </View>
            <View style={styles.descriptionContainer}>
              <View style={{ flex: 6, padding: 0.5 }}>
                <Text style={styles.firstContainerText}>
                  CAR PRICE with CIF
                </Text>
              </View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {data?.sales_quotation?.uob_rate
                    ? data.sales_quotation.uob_rate
                    : 0}
                </Text>
              </View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {data?.sales_quotation?.car_price_with_cif
                    ? formatCash(data.sales_quotation.car_price_with_cif)
                    : 0}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text
                  style={{ fontSize: 8, marginLeft: "auto", paddingRight: 5 }}
                >
                  {calculateCarPriceWithCIF(
                    data?.sales_quotation?.uob_rate,
                    data?.sales_quotation?.car_price_with_cif
                  )}
                </Text>
              </View>
            </View>
            <View style={[styles.descriptionContainer, { marginTop: 2 }]}>
              <View style={{ flex: 6, padding: 0.5 }}>
                <Text style={styles.firstContainerText}>
                  PAYABLE CUSTOMS DUTY **
                </Text>
              </View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                ></Text>
              </View>
              <View style={{ flex: 2, padding: 0.5 }}>
                {data?.sales_quotation?.estimated_price && (
                  <Text
                    style={{
                      fontSize: 8,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    ESTIMATED
                  </Text>
                )}
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text
                  style={{ fontSize: 8, marginLeft: "auto", paddingRight: 5 }}
                >
                  {data?.sales_quotation?.payable_custom_duty
                    ? formatCash(data.sales_quotation.payable_custom_duty)
                    : "-"}
                </Text>
              </View>
            </View>
            <View style={[styles.descriptionContainer, { marginTop: 2 }]}>
              <View style={{ flex: 6, padding: 0.5 }}>
                <Text style={{ color: "red", fontSize: 5 }}>
                  ** BUYER IS FULLY RESPONSIBLE TO PAY ANY ADDITIONAL DUTY IN
                  THE EVENT IF THERE IS ANY INCREASE IN THE FINAL DUTY **
                </Text>
              </View>
            </View>
            <View style={[styles.descriptionContainer, { marginTop: 2 }]}>
              <View style={{ flex: 6, padding: 0.5 }}>
                <Text style={styles.firstContainerText}>MISC</Text>
              </View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text style={{ fontSize: 6, marginLeft: 5 }}></Text>
              </View>
              <View style={{ flex: 2, padding: 0.5 }}>
                <Text style={{ fontSize: 6, marginLeft: 8 }}></Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text
                  style={{ fontSize: 8, marginLeft: "auto", paddingRight: 5 }}
                >
                  {data?.sales_quotation?.misc
                    ? formatCash(data.sales_quotation.misc)
                    : "-"}
                </Text>
              </View>
            </View>
            <TotalCommission
              totalTitle="car price"
              price={formatCash(data?.sales_quotation?.total_car_price)}
            />
            <View style={{ height: 25 }}>
              {data?.service_charge !== "0.00" && (
                <View style={styles.descriptionContainer}>
                  <View style={{ flex: 5, padding: 0.5 }}>
                    <Text style={styles.firstContainerText}>
                      SERVICE CHARGE
                    </Text>
                  </View>
                  <View style={{ flex: 3, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 8, // fontFamily: "Inter"
                      }}
                    >
                      {/* {data?.service_charge === "0.00"
                        ? "CASH BUYER"
                        : "LOAN BUYER"} */}
                      {data?.service_charge === "3.00"
                        ? "Cash Buyer"
                        : "Loan Buyer"}
                    </Text>
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 8, // fontFamily: "Inter"
                      }}
                    >
                      {data?.service_charge && data?.service_charge !== "0.00"
                        ? parseFloat(data.service_charge) + "%"
                        : "None"}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        paddingRight: 5,
                      }}
                    >
                      {data?.sales_quotation?.total_car_price &&
                      data?.service_charge
                        ? calculateServiceCharge()
                        : "-"}
                    </Text>
                  </View>
                </View>
              )}
              {data?.service_charge !== "0.00" && (
                <View style={styles.descriptionContainer}>
                  <View style={{ flex: 12, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 6,
                        justifyContent: "start",
                        textAlign: "left",
                        // fontFamily: "Inter",
                        marginTop: 2,
                      }}
                    >
                      **PRICE{" "}
                      {data?.service_charge === "3.00"
                        ? "EXCLUDED"
                        : "INCLUDED"}{" "}
                      1ST TIME SERVICING, INTERIOR & EXTERIOR TOUCH UP, ONE YEAR
                      WARRANTY**
                    </Text>
                  </View>
                </View>
              )}
            </View>
            <TotalCommission
              totalTitle="total car price"
              price={
                data?.sales_quotation?.total_cost_price
                  ? formatCash(
                      Math.floor(
                        data.sales_quotation.total_cost_price * Math.pow(10, 2)
                      ) / Math.pow(10, 2)
                    )
                  : "-"
              }
            />
          </View>

          {/* <div>
            <HeaderCommission title1="other charges" type="oneColumns" />
            {data?.sales_quotation?.other_charges.map((item, index) => (
              <View style={styles.descriptionContainer} key={index}>
                <View style={{ flex: 5, padding: 0.5 }}>
                  <Text style={styles.firstContainerText}>{item.label}</Text>
                </View>
                <View style={{ flex: 5, padding: 0.5 }}>
                  <Text style={styles.firstContainerText}> </Text>
                </View>
                <View style={{ flex: 1, padding: 0.5 }}>
                  <Text style={styles.rmColumnText}>RM</Text>
                </View>
                <View style={{ flex: 1, padding: 0.5, alignItems: "flex-end" }}>
                  <Text style={styles.priceColumnText}>
                    {item.detail ? formatCash(item.detail) : "-"}
                  </Text>
                </View>
              </View>
            ))}
            <TotalCommission
              totalTitle="total"
              price={
                data?.sales_quotation?.other_charges
                  ? calculateTotalCharges(data?.sales_quotation?.other_charges)
                  : "-"
              }
            />
          </div> */}
          <View
            style={[
              {
                marginTop: 8,
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
              },
            ]}
          >
            <HeaderCommission
              data={data}
              title1="other charges"
              type="oneBlueColumns"
            />
            <View style={{ minHeight: 80 }}>
              {data?.sales_quotation?.other_charges.map((item, index) => (
                <View style={[styles.descriptionContainer]} key={index}>
                  <View style={{ flex: 5, padding: 0.5 }}>
                    <Text style={styles.firstContainerText}>{item.label}</Text>
                  </View>
                  <View style={{ flex: 3, padding: 0.5 }}>
                    {item.label === "KASTAM Clearance" &&
                      data?.sales_quotation?.estimated_price && (
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          ESTIMATED
                        </Text>
                      )}
                    {item.label === "One Year Insurance" &&
                      data?.sales_quotation?.estimated_price && (
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          ESTIMATED
                        </Text>
                      )}
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text style={styles.firstContainerText}> </Text>
                  </View>
                  {item.label && (
                    <View style={{ flex: 1 }}>
                      <Text style={{ fontSize: 8, marginLeft: "auto" }}>
                        RM
                      </Text>
                    </View>
                  )}
                  <View style={{ flex: 2 }}>
                    {item.label && (
                      <Text
                        style={{
                          fontSize: 8,
                          marginLeft: "auto",
                          paddingRight: 5,
                        }}
                      >
                        {item.detail ? formatCash(item.detail) : "-"}
                      </Text>
                    )}
                  </View>
                </View>
              ))}
            </View>
            <TotalCommission
              totalTitle="total"
              price={
                data?.sales_quotation?.other_charges
                  ? calculateTotalCharges(data.sales_quotation.other_charges)
                  : ""
              }
            />
          </View>

          {/* <div>
            <View style={styles.grandRowContainer}>
              <View style={{ flex: 10, padding: 0.5 }}>
                <Text style={styles.firstContainerText}>GRAND TOTAL</Text>
              </View>
              <View style={{ flex: 1, padding: 0.5 }}>
                <Text style={styles.rmColumnText}>RM</Text>
              </View>
              <View style={{ flex: 1, padding: 0.5, alignItems: "flex-end" }}>
                <Text style={styles.priceColumnText}>
                  {calGrandTotal(
                    data?.sales_quotation?.total_car_price,
                    data?.sales_quotation?.total_cost_price,
                    data?.sales_quotation?.total_other_charges
                  )}
                </Text>
              </View>
            </View>
          </div> */}

          <View style={[styles.grandRowContainer, { marginTop: 8 }]}>
            <View style={{ flex: 10, padding: 0.5 }}>
              <Text style={styles.firstContainerText}>GRAND TOTAL</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text
                style={{ fontSize: 8, marginLeft: "auto", paddingRight: 5 }}
              >
                {calGrandTotal(
                  data?.sales_quotation?.total_cost_price,
                  data?.sales_quotation?.total_other_charges
                )}
              </Text>
            </View>
          </View>
          {/* 
          <div>
            <HeaderCommission
              title1="payments : "
              title2="remark"
              title3="type"
              title4="date"
              type="fourColumns"
            />
            {data?.sales_quotation?.total_payment.map((item, index) => (
              <View style={styles.descriptionContainer} key={index}>
                <View style={{ flex: 9, padding: 0.5 }}>
                  <Text style={styles.firstContainerText}>
                    {index + 1}
                    {index == 0 && "st"}
                    {index == 1 && "nd"}
                    {index == 2 && "rd"}
                    {index > 3 && "th"} Payment
                  </Text>
                </View>
                <View style={{ flex: 1, padding: 0.5 }}>
                  <Text style={styles.firstContainerText}>-</Text>
                </View>
                <View style={{ flex: 1, padding: 0.5 }}>
                  <Text style={styles.rmColumnText}>RM</Text>
                </View>
                <View style={{ flex: 1, padding: 0.5, alignItems: "flex-end" }}>
                  <Text style={styles.priceColumnText}>
                    {item.label ? formatCash(item.label) : "-"}
                  </Text>
                </View>
              </View>
            ))}
            <TotalCommission
              totalTitle="total"
              price={calculateTotalPayment(
                data?.sales_quotation?.total_payment
              )}
            />
          </div> */}
          <View
            style={[
              {
                marginTop: 8,
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
              },
            ]}
          >
            <HeaderCommission
              data={data}
              title1="payments"
              title2="remark"
              title3="type"
              title4="date"
              type="fourBlueColumns"
            />
            <View style={{ minHeight: "30px" }}>
              {data?.sales_quotation?.total_payment.map((item, index) => (
                <View style={styles.descriptionContainer} key={index}>
                  <View style={{ flex: 2 }}>
                    <Text style={styles.firstContainerText}>
                      {index + 1}
                      {index == 0 && "st"}
                      {index == 1 && "nd"}
                      {index == 2 && "rd"}
                      {index > 3 && "th"} Payment
                    </Text>
                  </View>
                  <View style={{ flex: 4 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      {item.remark}
                    </Text>
                  </View>
                  <View style={{ flex: 2 }}></View>
                  <View style={{ flex: 3, padding: 0.5 }}></View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        marginRight: "auto",
                        paddingLeft: 10,
                      }}
                    >
                      RM
                    </Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        paddingRight: 5,
                      }}
                    >
                      {item?.label ? formatCash(item.label) : "-"}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <TotalCommission
              totalTitle="total"
              price={calculateTotalPayment(
                data?.sales_quotation?.total_payment
              )}
            />
          </View>
          <div>
            {/* <Text
              style={{
                fontSize: 8,
                marginTop: 8,
                marginBottom: 2,
                // fontFamily: "Inter",
              }}
            >
              BANK LOAN - {data?.bank_loan_name}{" "}
              {data?.banker_name ? `(${data.banker_name})` : ""}
              {data?.sales_quotation?.loan_amount
                ? ` RM ${formatCash(data?.sales_quotation?.loan_amount)}`
                : ""}
            </Text>
            <View style={styles.balanceRowContainer}>
              <View style={{ flex: 10, padding: 0.5 }}>
                <Text style={styles.firstContainerText}>BALANCE</Text>
              </View>
              <View style={{ flex: 1, padding: 0.5 }}>
                <Text style={styles.rmColumnText}>RM</Text>
              </View>
              <View style={{ flex: 1, padding: 0.5, alignItems: "flex-end" }}>
                <Text style={styles.priceColumnText}>
                  {data?.sales_quotation?.total_balance
                    ? formatCash(data?.sales_quotation?.total_balance)
                    : ""}
                </Text>
              </View>
            </View> */}
            <View
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <View style={{ flex: 10, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    // fontFamily: "Inter",
                  }}
                >
                  BANK LOAN - {data?.bank_loan_name}{" "}
                  {data?.banker_name ? `(${data.banker_name})` : ""}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text
                  style={{ fontSize: 8, marginLeft: "auto", paddingRight: 5 }}
                >
                  {data?.sales_quotation?.loan_amount
                    ? `${formatCash(data?.sales_quotation?.loan_amount)}`
                    : ""}
                </Text>
              </View>
            </View>
            <View
              style={[styles.balanceRowContainer, { alignItems: "center" }]}
            >
              <View style={{ flex: 10, padding: 0.5 }}>
                <Text
                  style={[
                    styles.firstContainerText,
                    {
                      fontSize: 10,
                      fontFamily: "Inter-SemiBold",
                    },
                  ]}
                >
                  BALANCE
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: "auto",
                    paddingRight: 5,
                    fontFamily: "Inter-SemiBold",
                  }}
                >
                  {data?.sales_quotation?.total_balance
                    ? formatCash(
                        Math.floor(
                          data.sales_quotation.total_balance * Math.pow(10, 2)
                        ) / Math.pow(10, 2)
                      )
                    : ""}
                </Text>
              </View>
            </View>
          </div>

          {/* <div>
            <HeaderCommission title1="supplier invoice : " type="oneColumns" />
            <View style={styles.descriptionContainer}>
              <View style={{ flex: 12, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    justifyContent: "start",
                    textAlign: "left",
                    textDecoration: "underline",
                    // fontFamily: "Inter",
                  }}
                >
                  SUPPLIER NAME :{" "}
                </Text>
              </View>
            </View>
            {data?.commission_sub?.supplier_invoice.map((item, index) => (
              <View style={styles.descriptionContainer} key={index}>
                <View style={{ flex: 10, padding: 0.5 }}>
                  <Text style={styles.firstContainerText}>{item.label}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
                </View>
                <View style={{ flex: 2 }}>
                  <Text
                    style={{ fontSize: 8, marginLeft: "auto", paddingRight: 5 }}
                  >
                    {calGrandTotal(
                      data?.sales_quotation?.total_cost_price,
                      data?.sales_quotation?.total_other_charges
                    )}
                  </Text>
                </View>
              </View>
            ))}
            <View style={styles.descriptionContainer}>
              <View style={{ flex: 12, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    justifyContent: "start",
                    textAlign: "left",
                    // fontFamily: "Inter",
                  }}
                >
                  ..................
                </Text>
              </View>
            </View>
            <TotalCommission
              totalTitle="total"
              price={calculateSupplierInvoice(
                data?.commission_sub?.supplier_invoice
              )}
            />
          </div> */}
          <View
            style={[
              {
                marginTop: 8,
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
              },
            ]}
          >
            <View style={styles.commonYellowContainer}>
              <View style={{ flex: 12, padding: 0.5 }}>
                <Text style={styles.commonContainerBlackTitle}>
                  supplier invoice :
                </Text>
              </View>
            </View>
            {/* <HeaderCommission title1="supplier invoice : " type="oneColumns" /> */}
            <View style={{ minHeight: "30px" }}>
              {data?.commission_sub?.supplier_invoice.map((item, index) => (
                <View style={styles.descriptionContainer} key={index}>
                  <View style={{ flex: 10, padding: 0.5 }}>
                    <Text style={styles.firstContainerText}>{item.label}</Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        paddingRight: 5,
                      }}
                    >
                      {item.detail ? formatCash(item.detail) : "-"}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View>
              <View style={{ flex: 2 }}>
                <Text style={styles.firstContainerText}>
                  ..................
                </Text>
              </View>
              <View style={{ flex: 4 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                ></Text>
              </View>
              <View style={{ flex: 2 }}></View>
              <View style={{ flex: 3, padding: 0.5 }}></View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingLeft: 10,
                  }}
                ></Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: "auto",
                    paddingRight: 5,
                  }}
                ></Text>
              </View>
            </View>
            <TotalCommission
              totalTitle="total"
              price={calculateSupplierInvoice(
                data?.commission_sub?.supplier_invoice
              )}
            />
          </View>

          <div>
            <View style={{ marginTop: 6 }}>
              <Text style={styles.bottomContainerText}>**IMPORTANT**</Text>
              <Text style={styles.bottomSecondText}>
                1. Send all supplier invoice / bill to finance for checking &
                make payment. (if claim petty cash, please remark on invoice
                that)
              </Text>
              <Text style={styles.rmColumnText}>
                (If we paid commission already, the invoice/bill late send to
                finance, will paid yourself. Ask supplier change bil name.)
              </Text>
              <Text style={styles.bottomSecondText}>
                2. All of the commission submission will take 5 working days for
                document checking.
              </Text>
              <Text style={styles.rmColumnText}>
                Must complete all document and receive all payment from
                customer/ bank disbursement,
              </Text>
              <Text style={styles.rmColumnText}>
                We just issue the commission.
              </Text>
            </View>
          </div>

          <CommissionThirdTable data={data} />

          <div style={{ marginBottom: 6 }}>
            <View
              style={{ marginTop: 10, display: "flex", flexDirection: "row" }}
            >
              <View style={{ flex: 6, padding: 0.5 }}>
                <Text style={styles.bottomContainerText}>Submitted By :</Text>
                <Text style={styles.submitText}>
                  ...................................................
                </Text>
                <Text
                  style={{
                    fontSize: 8, // fontFamily: "Inter"
                  }}
                >
                  Name : {data?.sales_agent_name}
                </Text>
                <Text style={styles.dateText}>Date : </Text>
              </View>
              <View style={{ flex: 6, padding: 0.5 }}>
                <Text style={styles.bottomContainerText}>Approved By :</Text>
                <Text style={styles.submitText}>
                  ...................................................
                </Text>
                <Text
                  style={{
                    fontSize: 8, // fontFamily: "Inter"
                  }}
                >
                  Name :
                </Text>
                <Text style={styles.dateText}>Date :</Text>
              </View>
            </View>
          </div>

          <div style={styles.red_line}></div>
        </View>
      </Page>
    </Document>
  );
};

export default CommissionSub;
