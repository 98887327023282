import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
} from "@react-pdf/renderer";

import InterBold from "../fonts/Inter-Bold.ttf";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

Font.register({
  family: "Inter-Bold",
  src: InterBold,
});

const styles = StyleSheet.create({
  container: {
    fontFamily: "Inter-Bold",
  },
  borderBottom: {
    borderBottom: "1px solid black",
  },
  borderRight: {
    borderRight: "1px solid black",
  },
  table: {
    display: "flex",
    flexDirection: "row",
  },
});

const DescriptionVehicle = ({ data }) => {
  return (
    <View style={styles.container}>
      <View style={[styles.table, styles.borderBottom]}>
        <View style={[{ flex: 6 }, styles.borderRight]}>
          <Text
            style={{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }}
          >
            DESCRIPTION OF VEHICLE
          </Text>
        </View>
        <View style={[{ flex: 4 }]}></View>
      </View>
      <View style={[styles.table, styles.borderBottom]}>
        <View style={[{ flex: 2 }, styles.borderRight]}>
          <Text
            style={{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }}
          >
            MAKE:
          </Text>
          <Text
            style={{
              fontSize: "8px",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {data.vehicle_make}
          </Text>
        </View>
        <View style={[{ flex: 2 }, styles.borderRight]}>
          <Text
            style={{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }}
          >
            MODEL:
          </Text>
          <Text
            style={{
              fontSize: "8px",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {data.vehicle_model}
          </Text>
        </View>
        <View style={[{ flex: 2 }, styles.borderRight]}>
          <Text
            style={{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }}
          >
            YEAR:
          </Text>
          <Text
            style={{
              fontSize: "8px",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {data.vehicle_year}
          </Text>
        </View>
        <View style={[{ flex: 4 }]}>
          <Text
            style={{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }}
          >
            COLOUR:
          </Text>
          <Text
            style={{
              fontSize: "8px",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {data.vehicle_color}
          </Text>
        </View>
      </View>
      <View style={[styles.table, styles.borderBottom]}>
        <View style={[{ flex: 2 }, styles.borderRight]}>
          <Text
            style={{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }}
          >
            Chassis Number:
          </Text>
          <Text
            style={{
              fontSize: "8px",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {data.vehicle_chasis_no}
          </Text>
        </View>
        <View style={[{ flex: 2 }, styles.borderRight]}>
          <Text
            style={{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }}
          >
            Engine Number:
          </Text>
          <Text
            style={{
              fontSize: "8px",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {data.vehicle_engine_no}
          </Text>
        </View>
        <View style={[{ flex: 2 }, styles.borderRight]}>
          <Text
            style={{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }}
          >
            Stock No:
          </Text>
          <Text
            style={{
              fontSize: "8px",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {data.stock_no ? data.stock_no : ""}
          </Text>
        </View>
        <View style={[{ flex: 4 }]}>
          <Text
            style={{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }}
          >
            Engine c.c. Or HP:
          </Text>
          <Text
            style={{
              fontSize: "8px",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {data.vehicle_cc}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default DescriptionVehicle;
