import React, { useState } from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Text,
} from "@react-pdf/renderer";
import HeaderVehicleSales from "./components/header.vehicle.sales";
import DescriptionVehicle from "./components/description.vehicle";
import ParticularBuyer from "./components/particular.buyer";
import ImportantNote from "./components/important.note";
import VehicleSecondBalanceDetail from "./components/vehicle.second.balance.detail";
import VehicleFirstBalanceDetail from "./components/vehicle.first.balance.detail";
import VehicleAgreement from "./components/vehicle.agreement";

import InterBold from "./fonts/Inter-Bold.ttf";
// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Calibri-Regular",
//   src: require("./fonts/Calibri-Regular.ttf"),
// });

// Font.register({
//   family: "Cambria",
//   src: require("./fonts/Cambria.ttf"),
// });

// Font.register({
//   family: "Times",
//   src: require("./fonts/times.ttf"),
// });

Font.register({
  family: "Inter-Bold",
  src: InterBold,
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  mainView: {
    border: "1px solid black",
    height: "100%",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
});

const VsoForm = ({ data }) => {
  const [price, setPrice] = useState(0);

  const data_shown_name = data?.ap_detail?.shown_name;

  const renderBackgroundColor = () => {
    // switch (data_shown_name) {
    //   case "DCF":
    //     return "#D22B2B";
    //   case "DCFP":
    //     return "#D22B2B";
    //   case "DCK":
    //     return "#FFBF00";
    //   case "DCGS":
    //     // return "#538DD5";
    //     return "#0D6856";
    //   default:
    //     return "#D22B2B";
    // }
    switch (data_shown_name) {
      case "DCF":
        return "#D22B2B";
      case "DCFP":
        return "#FFBF00";
      case "DCK":
        return "#538DD5";
      case "DCGS":
        return "#0D6856";
      case "DCP":
        return "#c3a372";
      default:
        return "#D22B2B";
    }
  };

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.mainView}>
          <HeaderVehicleSales data={data} />
          <View
            style={[
              {
                backgroundColor: renderBackgroundColor(),
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              },
            ]}
          >
            <Text
              style={[
                {
                  fontSize: "8px",
                  fontFamily: "Inter-Bold",
                  fontWeight: "bold",
                  color: "#FBFBFB",
                  textAlign: "center",
                  display: "flex",
                  marginTop: "auto",
                  marginBottom: "auto",
                  padding: "5px",
                },
              ]}
            >
              VEHICLE SALES AND PURCHASE ORDER / PRE-ORDER
            </Text>
          </View>
          <DescriptionVehicle data={data} />
          <View style={[styles.rowContainer]}>
            <View
              style={[
                {
                  flex: 4.5,
                  borderRight: "1px solid black",
                },
              ]}
            >
              <ParticularBuyer data={data} />
              <ImportantNote data={data} />
            </View>
            <View style={[{ flex: 7.5 }]}>
              <VehicleFirstBalanceDetail data={data} setPrice={setPrice} />
              <VehicleSecondBalanceDetail data={data} price={price} />
              <VehicleAgreement data={data} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default VsoForm;
