import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import TwoColumnTable from "./components/two.column.table";
import HeaderCommission from "./components/header.commission";

// Font.register({
//   family: "Calibri-Bold",
//   src: require("./fonts/Calibri-Bold.TTF"),
// });

// Font.register({
//   family: "Calibri",
//   src: require("./fonts/Calibri Light.ttf"),
// });

// Font.register({
//   family: "Calibri-Regular",
//   src: require("./fonts/Calibri-Regular.ttf"),
// });

// Font.register({
//   family: "Calibri-Bold-Italic",
//   src: require("./fonts/Calibri Bold Italic.ttf"),
// });

// Font.register({
//   family: "Cambria",
//   src: require("./fonts/Cambria.ttf"),
// });

// Font.register({
//   family: "Times",
//   src: require("./fonts/times.ttf"),
// });

// Font.register({
//   family: "Times-Bold",
//   src: require("./fonts/times-bold.ttf"),
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  mainView: {
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
  },

  title: {
    alignSelf: "center",
    fontSize: 11,
    textAlign: "center",
    // fontFamily: "Calibri-Bold",
  },

  secondTitle: {
    alignSelf: "center",
    fontSize: 11,
    marginBottom: 40,
    textAlign: "center",
    // fontFamily: "Calibri-Bold",
  },

  inputColumn: {
    fontSize: 11,
    // fontFamily: "Calibri",
  },

  desc: {
    fontSize: 11,
    // fontFamily: "Calibri-Bold",
    marginTop: 20,
    marginBottom: 20,
  },

  secondDesc: {
    fontSize: 11,
    // fontFamily: "Calibri-Bold",
  },

  descItalic: {
    fontSize: 11,
    // fontFamily: "Calibri-Bold-Italic",
    marginBottom: 20,
  },

  topSecondText: {
    fontSize: 11,
    // fontFamily: "Calibri-Regular",
    marginBottom: 30,
  },

  twoBlankColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 20,
  },
  noMarginBlankColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  jpjK1ColumnText: {
    alignItems: "flex-end",
    fontSize: 11,
    // fontFamily: "Calibri-Bold",
  },
  jpjK1SecondColumnText: {
    alignItems: "flex-end",
    alignSelf: "flex-end",
    fontSize: 11,
    // fontFamily: "Calibri",
  },
  jpjK1ThirdColumnText: {
    alignItems: "flex-end",
    alignSelf: "center",
    fontSize: 11,
    // fontFamily: "Calibri",
  },
});

const JPJK1 = ({ data }) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.mainView}>
          <Text style={styles.title}>{data?.ap_detail?.name}</Text>
          <Text style={styles.secondTitle}>
            REQUEST FORM FOR JPJ K1 REGISTRATION
          </Text>
          <TwoColumnTable
            column1="Customer Name"
            column2={data?.personal_name}
            type="jpjK1List"
          />
          <TwoColumnTable
            column1="Model"
            column2={data?.vehicle_model}
            type="jpjK1List"
          />
          <TwoColumnTable
            column1="Chassis No"
            column2={data?.vehicle_chasis_no}
            type="jpjK1List"
          />

          <View style={styles.noMarginBlankColumn}>
            <View style={{ flex: 3, padding: 1 }}>
              <Text style={styles.jpjK1ColumnText}>Sales Agent Name</Text>
            </View>
            <View style={{ flex: 1, padding: 1 }}>
              <Text style={styles.jpjK1SecondColumnText}> : </Text>
            </View>
            <View style={{ flex: 8, border: "1px solid black", padding: 1 }}>
              <Text style={styles.jpjK1ThirdColumnText}>
                {data?.sales_agent_name} @ {data?.sales_agent_contact}
              </Text>
            </View>
          </View>

          <Text style={styles.desc}>
            * To attached with the Final / draft of Quotation.
          </Text>
          <Text style={styles.secondDesc}>
            Confirmation of customer full and final payment before proceed to
            registration.
          </Text>
          <Text style={styles.descItalic}>
            (may exclude road tax /registration fee / insurance).
          </Text>

          <View style={styles.twoBlankColumn}>
            <View style={{ flex: 3, padding: 1 }}>
              <Text style={styles.jpjK1ColumnText}>Remark</Text>
            </View>
            <View style={{ flex: 1, padding: 1 }}>
              <Text style={styles.jpjK1SecondColumnText}> : </Text>
            </View>
            <View style={{ flex: 8, border: "1px solid black", padding: 1 }}>
              <Text style={styles.jpjK1ThirdColumnText}>
                {data?.jpj?.remark}
              </Text>
            </View>
          </View>

          <View style={styles.twoBlankColumn}>
            <View style={{ flex: 4, padding: 1 }}></View>
            <View style={{ flex: 8, padding: 1, width: 100 }}>
              <HeaderCommission
                title1="Requested by:"
                title2="Checked by:"
                title3={data?.sales_agent_name}
                type="twoLeftColumns"
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default JPJK1;
