import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
  Tspan,
} from "@react-pdf/renderer";
import LogoImage from "../../../assets/images/logo-dark-full.png";
import moment from "moment";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Inter-Bold",
//   src: require("../fonts/Inter-Bold.ttf"),
// });

const styles = StyleSheet.create({
  headerContainer: {
    paddingTop: "10px",
  },
  headerTopSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerBottomSection: {},
  headerText: {
    // color: "#4E7DCD",
    // fontFamily: "Oswald",
    textAlign: "right",
  },
  headerCode: {
    // color: "#4E7DCD",
    fontSize: "9px",
    // fontFamily: "Oswald",
    // textAlign: "center",
    // marginLeft: "auto",
  },
  smallText: {
    fontSize: "8px",
  },
});

const HeaderVehicleSales = ({ data }) => {
  const data_shown_name = data?.ap_detail?.shown_name;

  const renderLogo = () => {
    switch (data_shown_name) {
      case "DCF":
        return require("../../../assets/images/FDS-logo.png");
      case "DCFP":
        return require("../../../assets/images/FP-logo.png");
      case "DCK":
        return require("../../../assets/images/KR-logo.png");
      case "DCGS":
        return require("../../../assets/images/GS-logo.JPG");
      case "DCP":
        return require("../../../assets/images/DCP-Logo.jpg");
      case "DCH AUTOMOBILE":
        return require("../../../assets/images/DCH-logo.png");
      default:
        return require("../../../assets/images/FDS-logo.png");
    }
  };
  const renderAddressAP = () => {
    const data_shown_name = data?.ap_detail?.shown_name;
    switch (data_shown_name) {
      case "DCF":
        return (
          <Text
            style={[
              styles.smallText,
              { textAlign: "center", textTransform: "uppercase" },
            ]}
          >
            NO. 1, JALAN ASTAKA 4/KU2, BANDAR BUKIT RAJA, 41050 KLANG, SELANGOR
            DARUL EHSAN.
          </Text>
        );
      // return (
      //   <View style={{ marginTop: 15 }}>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       NO. 1, JALAN ASTAKA 4/KU2,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       BANDAR BUKIT RAJA,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       41050 KLANG,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       SELANGOR DARUL EHSAN.
      //     </Text>
      //   </View>
      // );
      case "DCFP":
        return (
          <Text
            style={[
              styles.smallText,
              { textAlign: "center", textTransform: "uppercase" },
            ]}
          >
            No 3, JALAN ASTANA 1D, BANDAR BUKIT RAJA, 41050 KLANG, SELANGOR
          </Text>
        );
      // return (
      //   <View style={{ marginTop: 15 }}>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       No 3, JALAN ASTANA 1D,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       BANDAR BUKIT RAJA,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       41050 KLANG, SELANGOR
      //     </Text>
      //   </View>
      // );
      case "DCK":
        return (
          <Text
            style={[
              styles.smallText,
              { textAlign: "center", textTransform: "uppercase" },
            ]}
          >
            22A, Jalan Astana 1B, Bandar Bukit Raja, 41050 Klang Selangor.
          </Text>
        );
      // return (
      //   <View style={{ marginTop: 15 }}>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       22A, Jalan Astana 1B,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       Bandar Bukit Raja,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       41050 Klang Selangor.
      //     </Text>
      //   </View>
      // );
      case "DCGS":
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              No.3, Jalan Kenanga 1/1,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              Salak Perdana,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              43900 Sepang ,Selangor.
            </Text>
          </View>
        );
      case "DCP":
        return (
          <Text
            style={[
              styles.smallText,
              { textAlign: "center", textTransform: "uppercase" },
            ]}
          >
            No.6, Jalan Astana 1B, Bandar Bukit Raja, 41050 Klang ,Selangor.
          </Text>
        );
      case "DCH AUTOMOBILE":
        return (
          <Text
            style={[
              styles.smallText,
              { textAlign: "center", textTransform: "uppercase" },
            ]}
          >
            No.7, Jalan Astana 1F/KU2, Bandar Bukit Raja, 41050 Klang ,Selangor.
          </Text>
        );
      default:
        return (
          <Text
            style={[
              styles.smallText,
              { textAlign: "center", textTransform: "uppercase" },
            ]}
          >
            NO. 1, JALAN ASTAKA 4/KU2, BANDAR BUKIT RAJA, 41050 KLANG, SELANGOR
            DARUL EHSAN.
          </Text>
        );
    }
  };

  // const renderAddressAP = () => {
  //   switch (data?.ap_id) {
  //     case 1:
  //       return (
  //         <Text
  //           style={[
  //             styles.smallText,
  //             { textAlign: "center", textTransform: "uppercase" },
  //           ]}
  //         >
  //           {data?.ap_detail?.address}
  //         </Text>
  //       );
  //     case 2:
  //       return (
  //         <Text
  //           style={[
  //             styles.smallText,
  //             { textAlign: "center", textTransform: "uppercase" },
  //           ]}
  //         >
  //           {data?.ap_detail?.address}
  //         </Text>
  //       );
  //     case 3:
  //       return (
  //         <Text
  //           style={[
  //             styles.smallText,
  //             { textAlign: "center", textTransform: "uppercase" },
  //           ]}
  //         >
  //           {data?.ap_detail?.address}
  //         </Text>
  //       );
  //     default:
  //       return (
  //         <Text
  //           style={[
  //             styles.smallText,
  //             { textAlign: "center", textTransform: "uppercase" },
  //           ]}
  //         >
  //           {data?.ap_detail?.address}
  //         </Text>
  //       );
  //   }
  // };

  return (
    <View style={styles.headerContainer}>
      <View style={[styles.headerTopSection, { marginBottom: 5 }]}>
        <View style={{}}>
          <Image
            style={{
              width: data.ap_shown_name == "DCH AUTOMOBILE" ? "120px" : "90px",
              marginLeft: "auto",
              paddingRight: data.ap_shown_name == "DCH AUTOMOBILE" ? 10 : 0,
            }}
            src={renderLogo()}
          />
        </View>
        <View style={{}}>
          <Text
            style={[
              styles.headerText,
              {
                fontSize: data.ap_shown_name == "DCF" ? 20 : 17,
                paddingLeft: data.ap_shown_name == "DCH AUTOMOBILE" ? 15 : 0,
              },
            ]}
          >
            {data?.ap_detail?.name}
          </Text>
        </View>
        <View style={{}}>
          <Text style={styles.headerCode}>
            ({data?.ap_detail?.register_no})
          </Text>
        </View>
      </View>
      <View style={styles.headerBottomSection}>{renderAddressAP()}</View>
      {data_shown_name != "DCH AUTOMOBILE" && (
        <View
          style={[
            styles.smallText,
            styles.headerTopSection,
            { marginTop: "5px" },
          ]}
        >
          <View style={{ flex: 10 }}></View>
          <View style={[styles.headerTopSection, { flex: 2 }]}>
            <Text style={{ flex: 5 }}>Date:</Text>
            <Text style={{ flex: 5, textAlign: "right", paddingRight: "5px" }}>
              {/* {data?.created_at_formatted?.split(" ")[0]} */}
              {data?.vso?.date
                ? moment(data?.vso?.date).format("YYYY-MM-DD")
                : ""}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

export default HeaderVehicleSales;
