export const APP_NAME = "D'Concept";
export const PERSIST_STORE_NAME = "admin";
export const EMAIL_LIST_ENQUIRY = [
  "henrytan1234@yahoo.com",
  "wilson6373@gmail.com",
  "edlynchng2@gmail.com",
  "weiching_c@yahoo.com",
  "andy.dconcept@gmail.com",
  "dconcept.raytehks@gmail.com",
  "dconcept.daniel@gmail.com",
  "dconcept.kate@gmail.com",
  "chinlim0828@gmail.com",
  "catherinetan661698@gmail.com",
  "Davisdconcept@gmail.com",
  "rafiqdconcept@gmail.com",
];
export const DUTY_TAX_MANUAL_EMAIL = [
  "chia.cheeguan@gmail.com",
  "weiwei9314@gmail.com",
];
