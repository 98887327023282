import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import tw, { theme } from "twin.macro";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useConfig } from "../ConfigProvider";
import { useForm } from "../Form/context";
import { useInputGroup } from "../InputGroup/context";
import { HiCheck, HiChevronDown, HiX } from "react-icons/hi";
import Spinner from "../Spinner";
import { CONTROL_SIZES, SIZES } from "../utils/constant";
// import { debounce } from "lodash";
const cleanString = (str) => {
  // Convert to lowercase and remove special characters, symbols, and spaces
  if (!str) return "";
  return str.toLowerCase().replace(/[^a-z0-9]+/g, "");
};

// backup purpose
const customFilter = (option, inputValue) => {
  const cleanedOptionLabel = cleanString(option.label);
  const segments = inputValue.split(" ").map((segment) => cleanString(segment));

  // let currentIndex = 0;
  // for (let segment of segments) {
  //   if (currentIndex === -1) {
  //     return false;
  //   }
  //   currentIndex = cleanedOptionLabel.indexOf(segment, currentIndex);
  // }

  // return currentIndex !== -1;
  return segments.every((segment) => cleanedOptionLabel.includes(segment));
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
const generateHighlightedLabel = (label, inputValue) => {
  const inputChars = inputValue.split("").map((char) => escapeRegExp(char));
  const regex = new RegExp(`(${inputChars.join("|")})`, "gi");
  return label.replace(regex, "<strong>$1</strong>");
};

const DefaultOption = ({
  innerProps,
  label,
  labelWithHtml,
  selectProps,
  isSelected,
  isDisabled,
  disabled,
}) => {
  const { themeColor, inputValue, isBlueOption, useHtmlForOption } =
    selectProps;
  //   let highlightedLabel = label;

  let highlightedLabel = generateHighlightedLabel(label, inputValue);
  if (isBlueOption) {
    // Use regular expression to find and highlight the matched search term
    //   const regex2 = new RegExp(`(${inputValue})`, "gi");
    //   highlightedLabel = inputValue
    //     ? highlightedLabel.replace(regex2, "<strong>$1</strong>")
    //     : highlightedLabel;

    // Use regular expressions to find the first '[' and the text inside it
    const regex = /\[.+\]/;
    const matches = highlightedLabel.match(regex);
    // If a match is found, wrap the matched text in <span> with a class for styling
    if (matches) {
      matches.forEach((match) => {
        const replacedText = `[<span class="select-option-blue">${match}</span>]`;
        highlightedLabel = highlightedLabel.replace(match, replacedText);
      });
    }
  } else if (useHtmlForOption && labelWithHtml) {
    highlightedLabel = labelWithHtml;
  } else {
    const regex2 = new RegExp(`(${inputValue})`, "gi");
    // highlightedLabel =
    //   inputValue && typeof label === "string"
    //     ? label.replace(regex2, "<strong>$1</strong>")
    //     : label || ""; // Provide a default empty string if label is not a string
  }

  return (
    <div
      className={`select-option ${isSelected && "selected"} ${
        (isDisabled || disabled) && "disabled"
      }`}
      {...innerProps}
    >
      {/* <span className="ml-2">{label}</span> */}
      <span
        className="ml-2"
        dangerouslySetInnerHTML={{ __html: highlightedLabel }}
      />
      {isSelected && (
        <HiCheck className={`text-${themeColor} dark:text-white text-xl`} />
      )}
    </div>
  );
};

const DefaultDropdownIndicator = () => {
  return (
    <div className="select-dropdown-indicator">
      <HiChevronDown />
    </div>
  );
};

const DefaultClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <div className="select-clear-indicator">
        <HiX />
      </div>
    </div>
  );
};

const DefaultLoadingIndicator = ({ selectProps }) => {
  const { themeColor } = selectProps;
  return <Spinner className={`select-loading-indicatior text-${themeColor}`} />;
};

const Select = React.forwardRef((props, ref) => {
  const {
    size,
    style,
    className,
    form,
    field,
    components,
    isClearable,
    disabled,
    componentAs: Component,
    disableFocus,
    disableSearch,
    menuPlacement,
    ...rest
  } = props;

  const { themeColor, controlSize, primaryColorLevel, mode } = useConfig();
  const formControlSize = useForm()?.size;
  const inputGroupSize = useInputGroup()?.size;

  const selectSize = size || inputGroupSize || formControlSize || controlSize;

  const twColor = theme`colors`;
  const twHeight = theme`height`;

  let isInvalid = false;

  if (!isEmpty(form)) {
    const { touched, errors } = form;

    const touchedField = get(touched, field.name);
    const errorField = get(errors, field.name);

    isInvalid = touchedField && errorField;
  }

  const getBoxShadow = (state) => {
    const shadaowBase = "0 0 0 1px ";

    if (isInvalid) {
      return shadaowBase + twColor.red["500"];
    }

    if (state.isFocused) {
      return shadaowBase + twColor[themeColor][primaryColorLevel];
    }

    return "none";
  };

  const styles = {
    control: (provided, state) => {
      return {
        ...provided,
        height: twHeight[CONTROL_SIZES[selectSize]],
        minHeight: twHeight[CONTROL_SIZES[selectSize]],
        "&:hover": {
          boxShadow: getBoxShadow(state),
          cursor: "pointer",
        },
        boxShadow: getBoxShadow(state),
        borderRadius: tw`rounded-md`.borderRadius,
        ...(isInvalid ? { borderColor: twColor.red["500"] } : {}),
      };
    },
    input: (css) => {
      return {
        ...css,
        input: {
          outline: "none",
          outlineOffset: 0,
          boxShadow: "none !important",
        },
      };
    },
    menu: (provided) => ({ ...provided, zIndex: 50 }),
    ...style,
  };

  const selectClass = classNames("select", `select-${selectSize}`, className);
  return (
    <Component
      isClearable={isClearable}
      className={selectClass}
      classNamePrefix={"select"}
      ref={ref}
      // filterOption={customFilter}
      filterOption={disableSearch ? null : customFilter}
      isSearchable={!disableSearch}
      styles={styles}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral20:
            mode === "dark" ? twColor.gray["600"] : twColor.gray["300"],
          neutral30:
            mode === "dark" ? twColor.gray["600"] : twColor.gray["300"],
          neutral80: twColor.gray["700"],
          primary25: twColor[themeColor]["50"],
          primary50: twColor[themeColor]["100"],
          primary: twColor[themeColor][primaryColorLevel],
        },
      })}
      themeColor={`${themeColor}-${primaryColorLevel}`}
      components={{
        IndicatorSeparator: () => null,
        Option: (props) => {
          return (
            <DefaultOption
              {...props}
              disabled={disabled}
              inputValue={props.selectProps.inputValue}
              labelWithHtml={props.data.labelWithHtml}
            />
          );
        }, // Pass inputValue prop
        // Option: DefaultOption,
        LoadingIndicator: DefaultLoadingIndicator,
        DropdownIndicator: DefaultDropdownIndicator,
        ClearIndicator: DefaultClearIndicator,
        ...components,
      }}
      onFocus={(e) => {
        if (disableFocus) {
          e.preventDefault();
        }
      }}
      menuPlacement={menuPlacement}
      {...field}
      {...rest}
    />
  );
});

Select.propTypes = {
  size: PropTypes.oneOf([SIZES.LG, SIZES.MD, SIZES.SM]),
  componentAs: PropTypes.elementType,
  disableFocus: PropTypes.bool,
  disableSearch: PropTypes.bool,
};

Select.defaultProps = {
  componentAs: ReactSelect,
  disableFocus: false,
  disableSearch: false,
};

export default Select;
