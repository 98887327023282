import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import TotalCommission from "./total.commission";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Inter-Bold",
//   src: require("../fonts/Inter-Bold.ttf"),
// });

const styles = StyleSheet.create({
  descriptionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
  },
  firstContainerText: {
    fontSize: 8,
    fontWeight: 100,
    //transform: [{ scaleX: 30 }],
    // fontFamily: "Inter",
  },
  firstColumnText: {
    fontSize: 8,
    marginLeft: 5,
    // fontFamily: "Inter",
  },
  rmColumnText: {
    fontSize: 8,
    marginLeft: 7,
    // fontFamily: "Inter",
  },
  priceColumnText: {
    fontSize: 8,
    marginRight: 5,
    // fontFamily: "Inter",
  },
});

const calculateCarPriceWithCIF = (uobRate, currency) => {
  const total = uobRate * currency;
  const formatTotal = Number(total.toFixed(2));

  if (total > 0) {
    return formatTotal.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return "-";
};

const CommissionSecondTable = ({ data }) => {
  const formatCash = (cash) => {
    if (cash) {
      const formatTotal = Number(cash.toFixed(2));
      return formatTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "-";
  };

  const calculateServiceCharge = () => {
    let A = data?.sales_quotation?.total_car_price
      ? data.sales_quotation.total_car_price
      : 0;
    let B = data?.service_charge ? data.service_charge : 0;
    const total = A * (B / 100);

    const formatTotal = Number(total.toFixed(2));

    if (total > 0) {
      return formatTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return " -";
  };

  return (
    <div>
      <View style={styles.descriptionContainer}>
        <View style={{ flex: 6, padding: 0.5 }}>
          <Text style={styles.firstContainerText}>CAR PRICE with CIF</Text>
        </View>
        <View style={{ flex: 2, padding: 0.5 }}>
          <Text style={styles.firstColumnText}>
            {data?.sales_quotation?.uob_rate
              ? data.sales_quotation.uob_rate
              : 0}
          </Text>
        </View>
        <View style={{ flex: 2, padding: 0.5 }}>
          <Text style={{ fontSize: 8, marginLeft: 8 }}>
            {data?.sales_quotation?.car_price_with_cif
              ? formatCash(data.sales_quotation.car_price_with_cif)
              : 0}
          </Text>
        </View>
        <View style={{ flex: 1, padding: 0.5 }}>
          <Text style={styles.rmColumnText}>RM</Text>
        </View>
        <View style={{ flex: 1, padding: 0.5, alignItems: "flex-end" }}>
          <Text style={styles.priceColumnText}>
            {calculateCarPriceWithCIF(
              data?.sales_quotation?.uob_rate,
              data?.sales_quotation?.car_price_with_cif
            )}
          </Text>
        </View>
      </View>
      <View style={styles.descriptionContainer}>
        <View style={{ flex: 6, padding: 0.5 }}>
          <Text style={styles.firstContainerText}>PAYABLE CUSTOMS DUTY **</Text>
        </View>
        <View style={{ flex: 2, padding: 0.5 }}>
          <Text style={styles.firstColumnText}></Text>
        </View>
        <View style={{ flex: 2, padding: 0.5 }}>
          <Text style={{ fontSize: 8, marginLeft: 8 }}></Text>
        </View>
        <View style={{ flex: 1, padding: 0.5 }}>
          <Text style={styles.rmColumnText}>RM</Text>
        </View>
        <View style={{ flex: 1, padding: 0.5, alignItems: "flex-end" }}>
          <Text style={styles.priceColumnText}>
            {data?.sales_quotation?.payable_custom_duty
              ? formatCash(data.sales_quotation.payable_custom_duty)
              : "-"}
          </Text>
        </View>
      </View>
      <View style={styles.descriptionContainer}>
        <View style={{ flex: 6, padding: 0.5 }}>
          <Text style={styles.firstContainerText}>MISC</Text>
        </View>
        <View style={{ flex: 2, padding: 0.5 }}>
          <Text style={styles.firstColumnText}></Text>
        </View>
        <View style={{ flex: 2, padding: 0.5 }}>
          <Text style={{ fontSize: 8, marginLeft: 8 }}></Text>
        </View>
        <View style={{ flex: 1, padding: 0.5 }}>
          <Text style={styles.rmColumnText}>RM</Text>
        </View>
        <View style={{ flex: 1, padding: 0.5, alignItems: "flex-end" }}>
          <Text style={styles.priceColumnText}>
            {data?.sales_quotation?.misc
              ? formatCash(data.sales_quotation.misc)
              : "-"}
          </Text>
        </View>
      </View>
      <TotalCommission
        totalTitle="car price"
        price={formatCash(data?.sales_quotation?.total_car_price)}
      />
      <View style={{ height: 21 }}>
        {data?.service_charge !== "0.00" && (
          <View style={styles.descriptionContainer}>
            <View style={{ flex: 6, padding: 0.5 }}>
              <Text style={styles.firstContainerText}>SERVICE CHARGE</Text>
            </View>
            <View style={{ flex: 2, padding: 0.5 }}>
              <Text style={styles.firstColumnText}>
                {data?.service_charge === "3.00" ? "CASH BUYER" : "LOAN BUYER"}
              </Text>
            </View>
            <View style={{ flex: 2, padding: 0.5 }}>
              <Text style={styles.firstColumnText}>
                {data?.service_charge && data?.service_charge !== "0.00"
                  ? data?.service_charge + "%"
                  : "None"}
              </Text>
            </View>
            <View style={{ flex: 1, padding: 0.5 }}>
              <Text style={styles.rmColumnText}>RM</Text>
            </View>
            <View style={{ flex: 1, padding: 0.5, alignItems: "flex-end" }}>
              <Text style={styles.priceColumnText}>
                {data?.sales_quotation?.total_car_price && data?.service_charge
                  ? calculateServiceCharge()
                  : "-"}
              </Text>
            </View>
          </View>
        )}
        {data?.service_charge !== "0.00" && (
          <View style={styles.descriptionContainer}>
            <View style={{ flex: 12, padding: 0.5 }}>
              <Text
                style={{
                  fontSize: 7,
                  justifyContent: "start",
                  textAlign: "left",
                  // fontFamily: "Inter",
                  marginTop: 3,
                }}
              >
                ***PRICE{" "}
                {data?.service_charge === "3.00" ? "EXCLUDED" : "INCLUDED"} 1ST
                TIME SERVICING, INTERIOR & EXTERIOR TOUCH UP, ONE YEAR
                WARRANTY***
              </Text>
            </View>
          </View>
        )}
      </View>
      <TotalCommission
        totalTitle="total car price"
        price={
          data?.sales_quotation?.total_cost_price
            ? formatCash(data.sales_quotation.total_cost_price)
            : "-"
        }
      />
    </div>
  );
};

export default CommissionSecondTable;
