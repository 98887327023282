import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

// Font.register({
//   family: "Calibri-Bold",
//   src: require("../fonts/Calibri-Bold.TTF"),
// });

const styles = StyleSheet.create({
  column: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1.5px solid black",
    borderBottom: "none",
    // fontFamily: "Calibri-Bold",
    fontSize: 24,
    alignItems: "flex-end",
  },
  columnBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1.5px solid black",
    // fontFamily: "Calibri-Bold",
    fontSize: 24,
    alignItems: "flex-end",
  },
});

const VDForm2Table = ({ data }) => {
  return (
    <View style={{ marginTop: 10 }}>
      <View style={styles.column}>
        <View style={{ flex: 4 }}>
          <Text>MODEL</Text>
        </View>
        <View
          style={{
            flex: 1,
            borderRight: "1.5px solid black",
            textAlign: "right",
          }}
        >
          <Text>:{"\u00A0"}</Text>
        </View>
        <View style={{ flex: 7 }}>
          <Text>
            {"\u00A0"}
            {data?.vehicle_model}
          </Text>
        </View>
      </View>
      <View style={styles.column}>
        <View style={{ flex: 4 }}>
          <Text>CHASSIS</Text>
        </View>
        <View
          style={{
            flex: 1,
            borderRight: "1.5px solid black",
            textAlign: "right",
          }}
        >
          <Text>:{"\u00A0"}</Text>
        </View>
        <View style={{ flex: 7 }}>
          <Text>
            {"\u00A0"}
            {data?.vehicle_chasis_no}
          </Text>
        </View>
      </View>
      <View style={styles.column}>
        <View style={{ flex: 4 }}>
          <Text>ENGINE</Text>
        </View>
        <View
          style={{
            flex: 1,
            borderRight: "1.5px solid black",
            textAlign: "right",
          }}
        >
          <Text>:{"\u00A0"}</Text>
        </View>
        <View style={{ flex: 7 }}>
          <Text>
            {"\u00A0"}
            {data?.vehicle_engine_no}
          </Text>
        </View>
      </View>
      <View style={styles.column}>
        <View style={{ flex: 4 }}>
          <Text>YEAR</Text>
        </View>
        <View
          style={{
            flex: 1,
            borderRight: "1.5px solid black",
            textAlign: "right",
          }}
        >
          <Text>:{"\u00A0"}</Text>
        </View>
        <View style={{ flex: 7 }}>
          <Text>
            {"\u00A0"}
            {data?.vehicle_year}
          </Text>
        </View>
      </View>

      <View style={styles.columnBorder}>
        <View style={{ flex: 4 }}>
          <Text>SALES PERSON</Text>
        </View>
        <View
          style={{
            flex: 1,
            borderRight: "1.5px solid black",
            textAlign: "right",
          }}
        >
          <Text>:{"\u00A0"}</Text>
        </View>
        <View style={{ flex: 7 }}>
          <Text>
            {"\u00A0"}
            {data?.sales_agent_name}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default VDForm2Table;
