import { Text, StyleSheet, View, Font } from "@react-pdf/renderer";

// Font.register({
//   family: "Times-Bold",
//   src: require("../fonts/times-bold.ttf"),
// })

const styles = StyleSheet.create({
  dotted_line: {
    borderBottom: "1px dotted black",
    width: "70%",
    textAlign: "center",
    display: "inline-block",
  },
  title: {
    width: "15%",
    display: "inline-block",
  },
  row: {
    fontSize: 8.76,
    flexDirection: "row",
    alignItems: "center",
    margin: "0 auto",
    marginTop: 12,
    // fontFamily: "Times-Bold",
  },
});
const Single = ({ title, value }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.title}>{title} :</Text>
      <Text style={styles.dotted_line}>{value}</Text>
    </View>
  );
};

export default Single;
