import { MDBTooltip } from "mdb-react-ui-kit";

// 10
export function basicNumber(number) {
  const checkNumber = number ? number : 0;
  const newNumber = Number(checkNumber);

  const formattedNumber = parseFloat(newNumber).toFixed(0);

  return formattedNumber;
}
// 1,000.00  //hover
// export function formatNumber(number) {
//   const checkNumber = number ? String(number) : "0";

//   if (checkNumber.includes(",")) {
//     let numberString = checkNumber.replace(/,/g, "");
//     let [integerPart, decimalPart] = numberString.split(".");

//     if (decimalPart) {
//       decimalPart = decimalPart.substring(0, 2); // Take only first two digits
//     } else {
//       decimalPart = "00"; // If no decimal part, add '.00'
//     }

//     const formattedNumber = parseFloat(integerPart + "." + decimalPart).toLocaleString(undefined, {
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     });
//     return formattedNumber;
//   } else {
//     let numberString = checkNumber.replace(/,/g, "");

//     let [integerPart, decimalPart] = numberString.split(".");

//     if (decimalPart) {
//       decimalPart = decimalPart.substring(0, 2); // Take only first two digits
//     } else {
//       decimalPart = "00"; // If no decimal part, add '.00'
//     }

//     const formattedNumber = parseFloat(integerPart + "." + decimalPart).toLocaleString(undefined, {
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     });
//     return formattedNumber;
//   }
// }

// 1,000.00
export function formatNumber(number) {
  const checkNumber = number ? String(number) : "0";

  if (checkNumber.includes(",")) {
    let numberString = checkNumber.replace(/,/g, "");
    let [integerPart, decimalPart] = numberString.split(".");

    if (decimalPart) {
      decimalPart = decimalPart.substring(0, 2); // Take only first two digits
    } else {
      decimalPart = "00"; // If no decimal part, add '.00'
    }

    const formattedNumber = parseFloat(
      integerPart + "." + decimalPart
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  } else {
    let numberString = checkNumber.replace(/,/g, "");

    let [integerPart, decimalPart] = numberString.split(".");

    if (decimalPart) {
      decimalPart = decimalPart.substring(0, 2); // Take only first two digits
    } else {
      decimalPart = "00"; // If no decimal part, add '.00'
    }

    const formattedNumber = parseFloat(
      integerPart + "." + decimalPart
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  }
}

export function formatNumberTwo(number) {
  const checkNumber = number ? String(number) : "0";

  if (checkNumber.includes(",")) {
    const intValue = parseInt(checkNumber.replace(/,/g, ""), 10);
    const newNumber = Number(intValue);
    const formattedNumber = newNumber.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return (
      // <MDBTooltip tag={"a"} title={number}>
      formattedNumber
      // </MDBTooltip>
    );
  } else {
    const newNumber = Number(checkNumber);
    const formattedNumber = newNumber.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return (
      // <MDBTooltip tag={"a"} title={number}>
      formattedNumber
      // </MDBTooltip>
    );
  }
}
