import { Text, StyleSheet, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  dotted_line: {
    borderBottom: "1px dotted black",
    width: "25%",
    display: "inline-block",
    textAlign: "center",
    fontSize: 8.76,
    // fontFamily: "Times"
  },
  title: {
    width: "15%",
    display: "inline-block",
    marginLeft: 10,
    fontSize: 9.68,
    // fontFamily: "Times-Bold"
  },
  row: {
    fontSize: 12,
    flexDirection: "row",
    alignItems: "center",
    margin: "0 auto",
    marginTop: 12,
  },
  title2: {
    width: "15%",
    display: "inline-block",
    marginLeft: 10,
    fontSize: 9.68,
    // fontFamily: "Times-Bold"
  },
  dotted_line2: {
    borderBottom: "1px dotted black",
    width: "23%",
    display: "inline-block",
    textAlign: "center",
    fontSize: 8.76,
    // fontFamily: "Times"
  },
});
const Double = ({ payload, type }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.title}>{payload.title1} :</Text>
      <Text style={styles.dotted_line}>{payload.value1}</Text>
      <Text style={styles.title2}>{payload.title2} :</Text>
      <Text style={styles.dotted_line2}>{payload.value2}</Text>
    </View>
  );
};

export default Double;
