import OneSignal from "react-onesignal";
import { useDispatch } from "react-redux";
import {
  setInitialized,
  setNotificationStatus,
  postOneSignalSubscrlbe,
} from "store/commonReducer";
//dc-admin
export const initializeOneSignal = (dispatch, navigate, pathname) => {
  OneSignal.init({
    appId: process.env.REACT_APP_ONE_SIGNAL_ID,
    safari_web_id: process.env.REACT_APP_SAFARI_WEB_ID,
    autoRegister: false,
    allowLocalhostAsSecureOrigin: true,
    notifyButton: {
      enable: true,
      size: "small",
      position: "bottom-right",
      offset: {
        bottom: "55px",
        left: "0px" /* Only applied if bottom-left */,
        right: "0px" /* Only applied if bottom-right */,
      },
      theme: "default",
      prenotify: true,
      showCredit: false,
      text: {
        "tip.state.unsubscribed": "Stay up-to-date with important updates.",
        "tip.state.subscribed": `You're going to receive timely updates.`,
        "tip.state.blocked": "Access notification settings to enable updates.",
        "message.prenotify": "Click to allow notification.",
        "message.action.subscribed": "Notifications setting updated!",
        "message.action.resubscribed": "Notifications re-enabled successfully!",
        "message.action.unsubscribed": `You won't receive notifications anymore.`,
        "dialog.main.title": "Manage Notifications",
        "dialog.main.button.subscribe": "Notify Me",
        "dialog.main.button.unsubscribe": `Don't Notify Me`,
        "dialog.blocked.title": `Unblock Notifications`,
        "dialog.blocked.message":
          "Follow these instructions to allow notifications:",
      },
      colors: {
        "circle.background": "#ea580c",
        "circle.foreground": "#ffffff",
        "badge.background": "#ff0000",
        "badge.foreground": "#ffffff",
        "badge.bordercolor": "#ff0000",
        "pulse.color": "#dfdfdf",
      },
    },
    welcomeNotification: {
      title: "Welcome to Dconcept Admin Portal!",
      message: "Stay tuned for the latest updates.",
    },
  }).then(() => {
    dispatch(setInitialized(true));
    // if(pathname === '/sign-in') {
    //   navigate("/")
    // }
    const isUserPushSubscription =
      OneSignal.User && OneSignal.User.PushSubscription;

    const { permissionNative: notificationStatus } = OneSignal.Notifications;
    dispatch(setNotificationStatus(notificationStatus));

    const handleSubscriptionChange = async (event) => {
      const { optedIn: subscriptionStatus, id: subcriptionId } =
        OneSignal.User.PushSubscription;
      const { permissionNative: notificationStatus } = OneSignal.Notifications;
      if (isUserPushSubscription) {
        dispatch(
          postOneSignalSubscrlbe({
            subscription_id: subcriptionId,
            subscription_status: subscriptionStatus ? 0 : 1,
            notification_status: notificationStatus,
            type: "admin",
          })
        );
        dispatch(setNotificationStatus(notificationStatus));
      }
    };

    if (isUserPushSubscription) {
      OneSignal.User.PushSubscription.addEventListener(
        "change",
        handleSubscriptionChange
      );
    }

    return () => {
      if (isUserPushSubscription) {
        OneSignal.User.PushSubscription.removeEventListener(
          "change",
          handleSubscriptionChange
        );
      }
    };
  });
};

export const observeNotificationChanges = (notificationStatus) => {
  if (notificationStatus === "denied") {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes && mutation.addedNodes.length > 0) {
          const instructionElements =
            document.querySelectorAll(".instructions");

          instructionElements.forEach((element) => {
            const anchorTag = element.querySelector("a");
            if (anchorTag) {
              anchorTag.href =
                "https://i.ibb.co/t8L9Fk5/dc-admin-instruction.jpg";
            }

            const imgTag = element.querySelector("img");
            if (imgTag) {
              imgTag.src = "https://i.ibb.co/t8L9Fk5/dc-admin-instruction.jpg";
            }
          });
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }
};
