import React, { useRef, useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useConfig } from "../ConfigProvider";
import cloneDeep from "lodash/cloneDeep";
import FileItem from "./FileItemNew";
import Button from "../Buttons";
import CloseButton from "../CloseButton";
import Notification from "../Notification";
import toast from "../toast";
import heic2any from "heic2any";

const filesToArray = (files) => Object.keys(files).map((key) => files[key]);

const Upload = React.forwardRef((props, ref) => {
  const {
    fileUploadRef,
    accept,
    beforeUpload,
    uploadText,
    disabled,
    draggable,
    fileList,
    multiple,
    onChange,
    onFileRemove,
    showList,
    tip,
    uploadLimit,
    children,
    className,
    field,
    form,
    place_type = null,
    preview,
    desktopWidth,
    desktopHeight,
    mobileWidth,
    mobileHeight,
    actualDesktopHeight,
    actualDesktopWidth,
    actualMobileWidth,
    actualMobileHeight,
    typeOf,
    ...rest
  } = props;

  const fileInputField = useRef(null);
  const uploadRef = useRef(null);
  const [files, setFiles] = useState(fileList);
  const [dragOver, setDragOver] = useState(false);
  const [heicLoading, setHeicLoading] = useState(false);

  const { themeColor, primaryColorLevel } = useConfig();

  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  const triggerMessage = (msg) => {
    toast.push(
      <Notification type="danger" duration={2000}>
        {msg || "Upload Failed!"}
      </Notification>,
      {
        placement: "top-center",
      }
    );
  };

  const pushFile = (newFiles, file) => {
    for (let f of newFiles) {
      file.push(f);
    }
    return file;
  };

  const addNewFiles = (newFiles) => {
    let file = cloneDeep(files);
    if (typeof uploadLimit === "number" && uploadLimit !== 0) {
      if (Object.keys(file).length >= uploadLimit) {
        if (uploadLimit === 1) {
          file.shift();
          file = pushFile(newFiles, file);
        }

        return filesToArray({ ...file });
      }
    }
    file = pushFile(newFiles, file);
    return filesToArray({ ...file });
  };

  const onNewFileUpload = async (e) => {
    const { files: newFiles } = e.target;
    let result = true;
    let processedFiles = [];

    if (beforeUpload) {
      result = beforeUpload(newFiles, files);

      if (result === false) {
        triggerMessage();
        return;
      }

      if (typeof result === "string" && result.length > 0) {
        triggerMessage(result);
        return;
      }
    }

    for (let f of newFiles) {
      const fileName = f.name.toLowerCase();
      if (fileName.endsWith(".heic")) {
        setHeicLoading(true);
        try {
          const convertedBlob = await heic2any({
            blob: f,
            toType: "image/png",
          });
          const convertedFileName = f.name.replace(/\.heic$/i, ".png");
          const convertedFile = new File([convertedBlob], convertedFileName, {
            type: "image/png",
          });

          if (convertedFile) {
            setHeicLoading(false);
          }
          processedFiles.push(convertedFile);
        } catch (error) {
          console.error("Error converting HEIC file:", error);
        }
      } else {
        processedFiles.push(f);
      }
    }

    if (result && processedFiles.length > 0) {
      let updatedFiles = addNewFiles(processedFiles);
      setFiles(updatedFiles);
      onChange?.(updatedFiles, files);
    }
  };

  const removeFile = (fileIndex) => {
    const deletedFileList = files.filter((_, index) => index !== fileIndex);

    setFiles(deletedFileList);
    onFileRemove?.(deletedFileList);
  };

  const triggerUpload = (e) => {
    if (!disabled) {
      fileInputField.current?.click();
    }
    e.stopPropagation();
  };

  const renderChildren = () => {
    if (!draggable && !children) {
      return (
        <Button disabled={disabled} onClick={(e) => e.preventDefault()}>
          Upload
        </Button>
      );
    }

    if (draggable && !children) {
      return (
        <div
          className="my-16 text-center"
          style={{
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>{uploadText ?? "Choose a file or drag and drop here"}</span>
        </div>
      );
    }

    return children;
  };

  const handleDragLeave = useCallback(() => {
    if (draggable) {
      setDragOver(false);
    }
  }, [draggable]);

  const handleDragOver = useCallback(() => {
    if (draggable && !disabled) {
      setDragOver(true);
    }
  }, [draggable, disabled]);

  const handleDrop = useCallback(() => {
    if (draggable) {
      setDragOver(false);
    }
  }, [draggable]);

  const draggableProp = {
    onDragLeave: handleDragLeave,
    onDragOver: handleDragOver,
    onDrop: handleDrop,
  };

  const handleMouseEnter = (e) => {
    if (!disabled) {
      uploadRef.current?.focus();
    }
  };

  const handleMouseLeave = (e) => {
    if (!disabled) {
      uploadRef.current?.blur();
    }
  };

  const handlePaste = useCallback(
    (e) => {
      const clipboardData = e.clipboardData || window.clipboardData;

      if (clipboardData && clipboardData.items) {
        for (let i = 0; i < clipboardData.items.length; i++) {
          const item = clipboardData.items[i];

          if (item.kind === "file") {
            const blob = item.getAsFile();

            const file = new File([blob], `pasted-image-${Date.now()}.png`, {
              type: blob.type,
            });
            onNewFileUpload({ target: { files: [file] } });
          }
        }
      }
    },
    [onNewFileUpload]
  );

  const draggableEventFeedbackClass = `border-${themeColor}-${primaryColorLevel}`;

  const uploadClass = classNames(
    "upload",
    draggable && `upload-draggable`,
    draggable && !disabled && `hover:${draggableEventFeedbackClass}`,
    draggable && disabled && "disabled",
    dragOver && draggableEventFeedbackClass,
    className
  );

  const uploadInputClass = classNames("upload-input", draggable && `draggable`);

  //added during autoform image
  const checkFileList = (file) => {
    if (file.url && typeof file.url !== "string") {
      return true;
    } else if (typeof file !== "string" && typeof file.url !== "string") {
      return true;
    } else {
      return false;
    }
  };

  const handleFocus = useCallback(() => {
    uploadRef.current.contentEditable = "false";
  }, []);

  const handleBlur = useCallback(() => {
    uploadRef.current.contentEditable = "true";
  }, []);

  return (
    <>
      <div
        className="upload-draggable-div"
        style={{ position: "relative" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          ref={uploadRef}
          className={uploadClass}
          {...(draggable ? draggableProp : { onClick: triggerUpload })}
          {...rest}
          style={{ cursor: "pointer" }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
          onPaste={handlePaste}
          tabIndex={0}
          contentEditable
        ></div>
        <input
          hidden
          className={uploadInputClass}
          type="file"
          ref={fileUploadRef || fileInputField}
          onChange={onNewFileUpload}
          disabled={disabled}
          multiple={multiple}
          accept={accept}
          title=""
          value=""
          {...field}
          {...rest}
        ></input>
        {renderChildren()}
      </div>

      {tip}
      {showList && (
        <div
          className="upload-file-list"
          style={{ marginTop: files.length > 0 ? "0.5em" : "0.5em" }}
        >
          {heicLoading && (
            <div className="text-center mt-4 mb-4">Loading..</div>
          )}
          {files.map((file, index) => {
            //added during autoform image
            if (checkFileList(file)) {
              return (
                <FileItem
                  files={fileList}
                  file={file}
                  index={index}
                  key={file.name + index}
                  place_type={place_type}
                  preview={preview}
                  desktopWidth={desktopWidth}
                  desktopHeight={desktopHeight}
                  mobileWidth={mobileWidth}
                  mobileHeight={mobileHeight}
                  actualDesktopHeight={actualDesktopHeight}
                  actualDesktopWidth={actualDesktopWidth}
                  actualMobileWidth={actualMobileWidth}
                  actualMobileHeight={actualMobileHeight}
                  typeOf={typeOf}
                >
                  <CloseButton
                    onClick={() => removeFile(index)}
                    className="upload-file-remove"
                  />
                </FileItem>
              );
            }
          })}
        </div>
      )}
    </>
  );
});

Upload.propTypes = {
  uploadLimit: PropTypes.number,
  draggable: PropTypes.bool,
  disabled: PropTypes.bool,
  showList: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Upload.defaultProps = {
  draggable: false,
  showList: true,
  disabled: false,
  fileList: [],
  accept: "",
  multiple: false,
};

export default Upload;
