import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Inter-Bold",
//   src: require("../fonts/Inter.ttf"),
// });

const styles = StyleSheet.create({
  container: {
    // fontFamily: "Inter",
    height: "auto",
  },
  borderBottom: {
    borderBottom: "1px solid black",
  },
  borderRight: {
    borderRight: "1px solid black",
  },
  table: {
    display: "flex",
    gap: "1px",
    flexDirection: "row",
  },
});

const VehicleSecondBalanceDetail = ({ data, price }) => {
  const subBalance = (
    registerFee,
    inspectionFee,
    kastamClearance,
    roadTax,
    comprehensiveInsurance,
    firstAccessories,
    secondAccessories
  ) => {
    let A = registerFee ? Number(registerFee) : 0;
    let B = inspectionFee ? Number(inspectionFee) : 0;
    let C = kastamClearance ? Number(kastamClearance) : 0;
    let D = roadTax ? Number(roadTax) : 0;
    let E = comprehensiveInsurance ? Number(comprehensiveInsurance) : 0;
    let F = firstAccessories ? Number(firstAccessories) : 0;
    let G = secondAccessories ? Number(secondAccessories) : 0;

    const total = A + B + C + D + E + F + G;

    const formatTotal = Number(total.toFixed(2));

    if (total > 0) {
      return formatTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return "0.00";
  };

  const totalBalance = (firstSubBalance, secondSubBalance) => {
    let A = firstSubBalance ? Number(firstSubBalance) : 0;
    let B = secondSubBalance ? Number(secondSubBalance) : 0;

    const total = A + B;

    const formatTotal = Number(total.toFixed(2));

    if (total > 0) {
      return formatTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return total;
  };

  const data_shown_name = data?.ap_detail?.shown_name;

  const renderBackgroundColor = () => {
    // switch (data?.ap_id) {
    //   case 1:
    //     return "#D22B2B";
    //   case 2:
    //     return "#D22B2B";
    //   case 3:
    //     return "#FFBF00";
    //   case 4:
    //     return "#0D6856";
    //   default:
    //     return "#D22B2B";
    // }
    switch (data_shown_name) {
      case "DCF":
        return "#D22B2B";
      case "DCFP":
        return "#FFBF00";
      case "DCK":
        return "#538DD5";
      case "DCGS":
        return "#0D6856";
      case "DCP":
        return "#c3a372";
      default:
        return "#D22B2B";
    }
  };

  const formatCash = (cash) => {
    if (cash) {
      const formatTotal = Number(cash.toFixed(2));
      if (cash < 0) {
        const number = Math.abs(formatTotal);
        return `(${number.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })})`;
      } else {
        return formatTotal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    return "";
  };

  const windscreenAmountStr = data?.insurance?.windscreen_amount;
  const windscreenAmount = windscreenAmountStr
    ? parseFloat(windscreenAmountStr)
    : null;

  const coverageAmount =
    windscreenAmount !== null ? (windscreenAmount * 0.15).toFixed(2) : "____";

  const insuredAmount =
    windscreenAmount !== null ? windscreenAmount.toFixed(2) : "____";

  return (
    <View style={[styles.container, styles.borderBottom]}>
      {/* <View style={[styles.table]}>
        <Text
          style={[
            {
              fontSize: "8px",
              paddingLeft: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter-Bold",
            },
          ]}
        >
          FOR BUYER'S / HIRER'S ACCOUNT:
        </Text>
      </View> */}
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (A)
        </Text>
        <Text
          style={[
            {
              flex: 7,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          REGISTRATION FEE (PRIVATE)
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.registration_fee)}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (B)
        </Text>
        <Text
          style={[
            {
              flex: 7,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          INSPECTION & HANDLING FEES
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.inspection_handling_fee)}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (C)
        </Text>
        <Text
          style={[
            {
              flex: 7,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          KASTAM CLEARANCE
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.kastam_clearance)}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (D)
        </Text>
        <Text
          style={[
            {
              flex: 7,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          ONE YEAR ROAD TAX
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.one_year_road_tax)}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (E)
        </Text>
        <Text
          style={[
            {
              flex: 7,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          ONE YEAR COMPREHENSIVE INSURANCE
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.one_year_comprehensice_insurance)}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <Text
          style={[
            {
              flex: 11.5,
              fontSize: "7px",
              paddingRight: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          * Sum insured{" "}
          {data?.insurance?.sum_insurance_coverage_percentage ?? "____"}% RM
          {data?.insurance?.sum_insurance_coverage_price != null
            ? formatCash(data?.insurance?.sum_insurance_coverage_price)
            : "____"}{" "}
          (NCD {data?.insurance?.ncd ?? "____"})
        </Text>
      </View>

      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <Text
          style={[
            {
              flex: 11.5,
              fontSize: "7px",
              paddingRight: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          * Wind screen coverage RM{coverageAmount} (Amount insured RM
          {insuredAmount})
        </Text>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (F)
        </Text>
        <Text
          style={[
            {
              flex: 7,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          ADDITIONAL /OPTION ACCESSORIES
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          ></Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <Text
          style={[
            styles.borderBottom,
            {
              flex: 7,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (i) {data?.vso?.first_additional_accessories}
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.first_additional_accessories_price)}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.5,
              textAlign: "center",
              fontSize: "8px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <Text
          style={[
            styles.borderBottom,
            {
              flex: 7,
              fontSize: "8px",
              paddingRight: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (ii) {data?.vso?.second_additional_accessories}
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
                fontSize: "8px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                fontSize: "8px",
                paddingRight: "2px",
                paddingTop: "2px",
                textAlign: "center",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {formatCash(data?.vso?.second_additional_accessories_price)}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.table,
          {
            marginTop: "5px",
            backgroundColor: renderBackgroundColor(),
            color: "#FBFBFB",
            fontSize: "12px",
            fontFamily: "Inter-Bold",
          },
        ]}
      >
        <Text
          style={[
            {
              flex: 7,
              paddingRight: "15px",
              textAlign: "right",
            },
          ]}
        >
          2ND SUB BALANCE:
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                // paddingRight: "15px",
                textAlign: "right",
                textAlign: "center",
              },
            ]}
          >
            {subBalance(
              data?.vso?.registration_fee,
              data?.vso?.inspection_handling_fee,
              data?.vso?.kastam_clearance,
              data?.vso?.one_year_road_tax,
              data?.vso?.one_year_comprehensice_insurance,
              data?.vso?.first_additional_accessories_price,
              data?.vso?.second_additional_accessories_price
            )}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.table,
          {
            backgroundColor: renderBackgroundColor(),
            color: "#FBFBFB",
            fontSize: "12px",
            fontFamily: "Inter-Bold",
          },
        ]}
      >
        <Text
          style={[
            {
              flex: 7,
              paddingRight: "15px",
              textAlign: "right",
            },
          ]}
        >
          Total BALANCE:
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                // paddingRight: "15px",
                textAlign: "right",
                textAlign: "center",
              },
            ]}
          >
            {data?.vso?.total_balance
              ? formatCash(data.vso.total_balance)
              : "0.00"}
          </Text>
        </View>
      </View>
      {/* <View
        style={[
          styles.table,
          {
            backgroundColor: renderBackgroundColor(),
            color: "#FBFBFB",
            fontSize: "12px",
            // fontFamily: "Inter-Bold",
          },
        ]}
      >
        <Text
          style={[
            {
              flex: 7,
              marginTop: "3px",
              paddingRight: "15px",
              textAlign: "right",
            },
          ]}
        >
          Total BALANCE:
        </Text>
        <View style={[styles.table, { flex: 4.5 }]}>
          <Text
            style={[
              {
                flex: 1,
                textAlign: "center",
              },
            ]}
          >
            RM
          </Text>
          <Text
            style={[
              {
                flex: 6,
                // paddingRight: "15px",
                textAlign: "center",
              },
            ]}
          >
            {data?.vso?.total_balance
              ? formatCash(data.vso.total_balance)
              : "0.00"}
          </Text>
        </View>
      </View> */}
    </View>
  );
};

export default VehicleSecondBalanceDetail;
