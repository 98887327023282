import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
  Tspan,
} from "@react-pdf/renderer";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Calibri-Bold",
//   src: require("../fonts/Calibri-Bold.TTF"),
// });

// Font.register({
//   family: "Calibri",
//   src: require("../fonts/Calibri Light.ttf"),
// });

// Font.register({
//   family: "Cambria",
//   src: require("../fonts/Cambria.ttf"),
// });

const styles = StyleSheet.create({
  twoColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    borderBottom: "none",
  },
  twoBlankColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  columnText: {
    marginTop: "auto",
    marginBottom: "auto",
    alignItems: "flex-end",
    fontSize: 13,
    color: "black",
    // fontFamily: "Calibri",
  },

  custColumnText: {
    alignItems: "flex-end",
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },
  custSecondColumnText: {
    alignItems: "flex-end",
    marginLeft: 8,
    fontSize: 11,
    color: "black",
    // fontFamily: "Cambria",
  },

  eHakColumnText: {
    alignItems: "flex-center",
    marginLeft: 8,
    fontSize: 14,
    color: "black",
    // fontFamily: "Calibri-Regular",
  },

  invColumnText: {
    alignItems: "flex-end",
    fontSize: 14,
    color: "black",
    // fontFamily: "Calibri-Bold",
  },
  invSecondColumnText: {
    alignItems: "flex-end",
    fontSize: 17,
    marginLeft: 8,
    color: "black",
    // fontFamily: "Calibri-Regular",
  },

  jpjK1ColumnText: {
    alignItems: "flex-end",
    fontSize: 11,
    // fontFamily: "Calibri-Bold",
  },
  jpjK1SecondColumnText: {
    alignItems: "flex-end",
    alignSelf: "flex-end",
    fontSize: 11,
    // fontFamily: "Calibri",
  },
  jpjK1ThirdColumnText: {
    alignItems: "flex-end",
    alignSelf: "center",
    fontSize: 11,
    // fontFamily: "Calibri",
  },
});

const TwoColumnTable = ({ column1, column2, type }) => {
  return (
    <>
      {type == "customerList" ? (
        <View style={styles.twoColumn}>
          <View style={{ flex: 5, borderRight: "1px solid black", padding: 1 }}>
            <Text style={styles.custColumnText}>{column1}</Text>
          </View>
          <View style={{ flex: 7, padding: 1 }}>
            <Text style={styles.custSecondColumnText}>{column2}</Text>
          </View>
        </View>
      ) : type == "eHakList" ? (
        <View style={styles.twoColumn}>
          <View
            style={{ flex: 5, borderRight: "1px solid black", padding: 10 }}
          >
            <Text style={styles.eHakColumnText}>{column1}</Text>
          </View>
          <View style={{ flex: 7, padding: 10 }}>
            <Text style={styles.eHakColumnText}>{column2}</Text>
          </View>
        </View>
      ) : type == "customerInvList" ? (
        <View style={styles.twoColumn}>
          <View style={{ flex: 5, borderRight: "1px solid black", padding: 1 }}>
            <Text style={styles.invColumnText}>{column1}</Text>
          </View>
          <View style={{ flex: 7, padding: 1 }}>
            <Text style={styles.invSecondColumnText}>{column2}</Text>
          </View>
        </View>
      ) : type == "jpjK1List" ? (
        <View style={styles.twoBlankColumn}>
          <View style={{ flex: 3, padding: 1 }}>
            <Text style={styles.jpjK1ColumnText}>{column1} </Text>
          </View>
          <View style={{ flex: 1, padding: 1 }}>
            <Text style={styles.jpjK1SecondColumnText}> : </Text>
          </View>
          <View
            style={{
              flex: 8,
              border: "1px solid black",
              borderBottom: "none",
              padding: 1,
            }}
          >
            <Text style={styles.jpjK1ThirdColumnText}>{column2}</Text>
          </View>
        </View>
      ) : (
        <View style={styles.twoColumn}>
          <View style={{ flex: 5, borderRight: "1px solid black", padding: 1 }}>
            <Text style={styles.columnText}>{column1}</Text>
          </View>
          <View style={{ flex: 7, padding: 1 }}>
            <Text style={styles.columnText}>{column2}</Text>
          </View>
        </View>
      )}
    </>
  );
};

export default TwoColumnTable;
