import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
  Tspan,
} from "@react-pdf/renderer";

import InterSemiBold from "../fonts/Inter-SemiBold.ttf";
import Bold from "../fonts/Inter-Bold.ttf";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

Font.register({
  family: "Inter-Bold",
  src: Bold,
});

Font.register({
  family: "Inter-SemiBold",
  src: InterSemiBold,
});

const styles = StyleSheet.create({
  totalContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
    backgroundColor: "black",
  },
  totalContainerWhiteTitle: {
    margin: 0,
    fontSize: 8,
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Inter-Bold",
  },
});

const TotalCommission = ({ totalTitle, price }) => {
  return (
    <View style={[styles.totalContainer, { alignItems: "center" }]}>
      <View style={{ flex: 10, padding: 0.5 }}>
        <Text style={styles.totalContainerWhiteTitle}>{totalTitle}</Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text
          style={
            totalTitle == "total"
              ? {
                  fontSize: 8,
                  marginLeft: "auto",
                  color: "white",
                  fontFamily: "Inter-Bold",
                }
              : {
                  fontSize: 8,
                  marginLeft: "auto",
                  color: "white",
                  fontFamily: "Inter-Bold",
                }
          }
        >
          RM
        </Text>
      </View>
      <View style={{ flex: 2 }}>
        <Text
          style={{
            fontSize: 8,
            marginLeft: "auto",
            paddingRight: 5,
            color: "white",
            fontFamily: "Inter-Bold",
          }}
        >
          {price}
        </Text>
      </View>
    </View>
  );
};

export default TotalCommission;

{
  /* <View style={{ flex: 1 }}>
<Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
</View>
<View style={{ flex: 2 }}>
<Text
  style={{ fontSize: 8, marginLeft: "auto", paddingRight: 5 }}
> */
}
