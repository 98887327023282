import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import K1Logo from "./../../assets/images/k1-logo.jpg";
import VDForm2Table from "./components/vdform2.table";
import moment from "moment";

// Font.register({
//   family: "Calibri-Bold",
//   src: require("./fonts/Calibri-Bold.TTF"),
// });

// Font.register({
//   family: "Calibri",
//   src: require("./fonts/Calibri Light.ttf"),
// });

// Font.register({
//   family: "Calibri-Regular",
//   src: require("./fonts/Calibri-Regular.ttf"),
// });

// Font.register({
//   family: "Cambria",
//   src: require("./fonts/Cambria.ttf"),
// });

// Font.register({
//   family: "Times",
//   src: require("./fonts/times.ttf"),
// });

// Font.register({
//   family: "Times-Bold",
//   src: require("./fonts/times-bold.ttf"),
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 50,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  mainView: {
    position: "relative",
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    alignSelf: "center",
    fontSize: 22,
    // fontFamily: "Calibri-Bold",
  },
  subHeader: {
    // fontSize: 44,
    // fontFamily: "Calibri-Bold",
    color: "#D22B2B",
  },
  titleText: {
    fontSize: 60,
    // fontFamily: "Calibri-Bold",
    color: "#D22B2B",
  },
  column: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1.5px solid black",
    borderBottom: "none",
    // fontFamily: "Calibri-Bold",
    fontSize: 24,
    alignItems: "flex-end",
  },
  columnBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1.5px solid black",
    // fontFamily: "Calibri-Bold",
    fontSize: 24,
    alignItems: "flex-end",
  },
  blueHeader: {
    padding: 18,
    backgroundColor: "#538DD5",
  },
});

// const fdsData = {
//   "MODEL": "Input 5 Input 5",
//   "CHASSIS" : "Input 5",
//   "ENGINE" : "Input 5",
//   "YEAR": "Input 5",
// }

const VDForm2 = ({ data }) => {
  const renderApName = () => {
    // switch (data?.ap_id) {
    //   case 1:
    //     return "FDS";
    //   case 2:
    //     return "FP";
    //   case 3:
    //     return "KPG";
    //   case 4:
    //     return "GS";
    //   default:
    return data?.ap_detail?.shown_name;
    // }
  };

  const title_vehicle_cc = data?.vehicle_cc ? `${data.vehicle_cc}CC` : "";
  const title_vehicle_register_date = data?.vehicle_register_date
    ? moment(data.vehicle_register_date).format("YYYYMMMM")
    : "";

  const vehicleDetailsTitle = [
    "VEHICLE DETAILS",
    title_vehicle_cc,
    title_vehicle_register_date,
  ]
    .filter(Boolean)
    .join(" / ");

  return (
    <Document>
      <Page orientation="landscape" style={styles.body}>
        <View style={styles.mainView}>
          <Text style={styles.header}> {vehicleDetailsTitle} </Text>
          <View style={[styles.row, { marginTop: 20 }]}>
            <View style={{ flex: 6, textAlign: "right" }}>
              <View
                style={{
                  marginTop: 10,
                }}
              >
                <Text
                  style={[
                    styles.subHeader,
                    {
                      fontSize: renderApName() == "DCH AUTOMOBILE" ? 25 : 44,
                      paddingBottom:
                        renderApName() == "DCH AUTOMOBILE" ? 20 : 0,
                    },
                  ]}
                >
                  {renderApName()} SOLD
                </Text>
              </View>
            </View>
            <View
              style={{
                flex: 6,
                // marginTop: 35,
                textAlign: "right",
              }}
            >
              <View
                style={{
                  position: "absolute",
                  top: "-35",
                  right: "-45",
                  transform: "translate(-50%,0)",
                  textAlign: "center",
                  width: 100,
                  border: "2px solid black",
                  borderRadius: "50%",
                }}
              >
                <Text
                  style={[
                    styles.titleText,
                    { marginTop: 15, marginBottom: 15 },
                  ]}
                >
                  {data?.title}
                </Text>
              </View>
            </View>
          </View>
          <VDForm2Table data={data} />

          {/* <View
            style={[
              styles.blueHeader,
              { backgroundColor: renderBackgroundColor() },
            ]}
          ></View> */}
        </View>
      </Page>
    </Document>
  );
};

export default VDForm2;
