import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
} from "@react-pdf/renderer";

import InterBold from "../fonts/Inter.ttf";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

Font.register({
  family: "Inter-Bold",
  src: InterBold,
});

const styles = StyleSheet.create({
  container: {
    height: "auto",
    // fontFamily: "Inter",
    paddingBottom: "12px",
    borderBottom: "1px solid black",
  },
  borderBottom: {
    borderBottom: "1px solid black",
  },
  borderRight: {
    borderRight: "1px solid black",
  },
  table: {
    display: "flex",
    flexDirection: "row",
  },
});

const ParticularBuyer = ({ data }) => {
  return (
    <View style={[styles.container]}>
      <View style={[styles.table, styles.borderBottom]}>
        <Text
          style={[
            {
              fontSize: "8px",
              paddingLeft: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          PARTICULARS OF BUYER/HIRER:
        </Text>
      </View>
      <View style={[styles.table]}>
        <View style={{ flex: 4 }}>
          <Text
            style={[
              { fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" },
              styles.borderBottom,
            ]}
          >
            Customer Name
          </Text>
        </View>
        <Text
          style={[{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }]}
        >
          :
        </Text>
        <View style={{ flex: 8 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingLeft: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data?.personal_name ? data.personal_name : " "}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <View style={{ flex: 4 }}>
          <Text
            style={[
              { fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" },
              styles.borderBottom,
            ]}
          >
            ADDRESS
          </Text>
        </View>
        <Text
          style={[{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }]}
        >
          :
        </Text>
        <View style={{ flex: 8 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingLeft: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data?.address ? data.address : " "}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <View style={{ flex: 4 }}>
          <Text
            style={[
              { fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" },
              styles.borderBottom,
            ]}
          >
            I/C No.
          </Text>
        </View>
        <Text
          style={[{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }]}
        >
          :
        </Text>
        <View style={{ flex: 8 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingLeft: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data?.identity_no ? data.identity_no : " "}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <View style={{ flex: 4 }}>
          <Text
            style={[
              { fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" },
              styles.borderBottom,
            ]}
          >
            EMPLOYERS
          </Text>
        </View>
        <Text
          style={[{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }]}
        >
          :
        </Text>
        <View style={{ flex: 8 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingLeft: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data?.vso?.employers ? data.vso.employers : " "}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <View style={{ flex: 4 }}>
          <Text
            style={[
              { fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" },
              styles.borderBottom,
            ]}
          >
            HP/HOME (TEL)
          </Text>
        </View>
        <Text
          style={[{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }]}
        >
          :
        </Text>
        <View style={{ flex: 8 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingLeft: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data.personal_contact ? data.personal_contact : " "}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <View style={{ flex: 4 }}>
          <Text
            style={[
              { fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" },
              styles.borderBottom,
            ]}
          >
            TEL (OFFICE)
          </Text>
        </View>
        <Text
          style={[{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }]}
        >
          :
        </Text>
        <View style={{ flex: 8 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingLeft: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data?.vso?.tel_office ? data?.vso?.tel_office : " "}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <View style={{ flex: 4 }}>
          <Text
            style={[
              { fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" },
              styles.borderBottom,
            ]}
          >
            FAX
          </Text>
        </View>
        <Text
          style={[{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }]}
        >
          :
        </Text>
        <View style={{ flex: 8 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingLeft: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data?.vso?.fax ? data.vso.fax : " "}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <View style={{ flex: 4 }}>
          <Text
            style={[
              { fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" },
              styles.borderBottom,
            ]}
          >
            EMAIL
          </Text>
        </View>
        <Text
          style={[{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }]}
        >
          :
        </Text>
        <View style={{ flex: 8 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingLeft: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data?.vso?.email ? data.vso.email : " "}
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <View style={{ flex: 4 }}>
          <Text
            style={[
              { fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" },
              styles.borderBottom,
            ]}
          >
            OCCUPATION
          </Text>
        </View>
        <Text
          style={[{ fontSize: "8px", paddingLeft: "2px", paddingTop: "2px" }]}
        >
          :
        </Text>
        <View style={{ flex: 8 }}>
          <Text
            style={[
              {
                fontSize: "8px",
                paddingLeft: "2px",
                paddingTop: "2px",
                fontFamily: "Inter-Bold",
              },
              styles.borderBottom,
            ]}
          >
            {data?.vso?.occupation ? data.vso.occupation : " "}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ParticularBuyer;
