import { View, Text, StyleSheet, Font } from "@react-pdf/renderer";

// Font.register({
//   family: "Times",
//   src: require("../fonts/times.ttf"),
// })
// Font.register({
//   family: "Seguisym",
//   src: require("../fonts/seguisym.ttf"),
// })

const styles = StyleSheet.create({
  fiveColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    borderBottom: "none",
    borderTop: "none",
  },
  fiveColumnText: {
    alignSelf: "center",
    fontSize: 8.76,
    color: "black",
    // fontFamily: 'Times',
  },
  symbolText: {
    alignSelf: "center",
    fontSize: 7.39,
    color: "black",
    // fontFamily: 'Seguisym',
  },
});
const Table = ({ col1, col2, col3, col4 }) => {
  return (
    <View style={styles.fiveColumn}>
      <View
        style={{
          flex: 1,
          borderRight: "1px solid black",
          borderTop: "1px solid black",
          padding: 1,
        }}
      >
        <Text style={styles.fiveColumnText}>{col1}</Text>
      </View>
      <View
        style={{
          flex: 4,
          padding: 1,
          borderRight: "1px solid black",
          borderTop: "1px solid black",
        }}
      >
        <Text style={styles.fiveColumnText}>{col2}</Text>
      </View>
      <View
        style={{
          flex: 1,
          padding: 1,
          borderRight: "1px solid black",
          borderTop: "1px solid black",
        }}
      >
        {/* <Text style={styles.symbolText}> */}
        {col3}
        {/* </Text> */}
      </View>
      <View
        style={{
          flex: 1,
          padding: 1,
          borderTop: "1px solid black",
        }}
      >
        {col4}
      </View>
    </View>
  );
};

export default Table;
