import React from "react";
import { Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import ThreeColumnTable from "./three.column.table";
import TickIcon from "../../../assets/images/tick_icon.png";
// Font.register({
//   family: "Seguisym",
//   src: require("../fonts/seguisym.ttf"),
// });

// Font.register({
//   family: "Calibri-Bold",
//   src: require("../fonts/Calibri-Bold.TTF"),
// });

const styles = StyleSheet.create({
  threeBlankColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  secFirstColumn: {
    alignSelf: "center",
    fontSize: 14,
    // fontFamily: "Calibri-Bold",
  },
  secSecondColumn: {
    fontSize: 14,
    // fontFamily: "Calibri-Bold",
  },
  secThirdColumn: {
    fontSize: 9.25,
    alignSelf: "center",
    // fontFamily: "Seguisym",
  },
});

const SalesInvTable = ({ data }) => {
  return (
    <div>
      <ThreeColumnTable
        column1={1}
        column2="Final Quotation"
        column3={data?.sales_invoice?.final_quotation}
        type="salesInvList"
      />
      <ThreeColumnTable
        column1={2}
        column2="VSO"
        column3={data?.sales_invoice?.vso}
        type="salesInvList"
      />
      <ThreeColumnTable
        column1={3}
        column2="Insurance Cover Note"
        column3={data?.sales_invoice?.insurance_cover_note}
        type="salesInvList"
      />
      <ThreeColumnTable
        column1={4}
        column2="LOU (If Loan)"
        column3={data?.sales_invoice?.lou}
        type="salesInvList"
      />
      <ThreeColumnTable
        column1={5}
        column2="Registration card"
        column3={data?.sales_invoice?.registration_card}
        type="salesInvList"
      />
      <ThreeColumnTable
        column1={6}
        column2="Road tax"
        column3={data?.sales_invoice?.road_tax}
        type="salesInvList"
      />
      <ThreeColumnTable
        column1={7}
        column2="JPJ B2 / B7"
        column3={data?.sales_invoice?.jpj_b2_and_b7}
        type="salesInvList"
      />

      <View style={styles.threeBlankColumn}>
        <View style={{ flex: 2, padding: 2 }}>
          <Text style={styles.secFirstColumn}>8</Text>
        </View>
        <View style={{ flex: 4, padding: 2 }}>
          <Text style={styles.secSecondColumn}>JPJ K1</Text>
        </View>
        <View
          style={{
            height: 25,
            flex: 1,
            padding: 2,
            border: "1px solid black",
          }}
        >
          {/* <Text style={styles.secThirdColumn}>
            {data?.sales_invoice?.jpj_k1}
          </Text> */}
          {data?.sales_invoice?.jpj_k1 == "yes" && <Image src={TickIcon} />}
        </View>
        <View style={{ flex: 5 }}></View>
      </View>
    </div>
  );
};

export default SalesInvTable;
