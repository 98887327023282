import React from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Text,
} from "@react-pdf/renderer";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Calibri-Regular",
//   src: require("./fonts/Calibri-Regular.ttf"),
// });

// Font.register({
//   family: "Cambria",
//   src: require("./fonts/Cambria.ttf"),
// });

// Font.register({
//   family: "Times",
//   src: require("./fonts/times.ttf"),
// });
// Font.register({
//   family: "Inter-Bold",
//   src: require("./fonts/Inter-Bold.ttf"),
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 100,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  mainView: {
    // fontFamily: "Calibri-Regular",
    height: "100%",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
});

const VDForm1 = ({ data }) => {
  return (
    <Document>
      <Page orientation="landscape" style={styles.body}>
        <View style={[styles.mainView, { textAlign: "center" }]}>
          <Text style={{ fontWeight: "bold", fontSize: "25px" }}>
            VEHICLE DETAILS
          </Text>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "55px",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            {data.title}
          </Text>
          <Text style={{ fontWeight: "bold", fontSize: "40px" }}>
            {data?.vehicle_year} {data?.vehicle_make} {data?.vehicle_model}
          </Text>
          <Text style={{ fontWeight: "bold", fontSize: "40px" }}>
            {data?.vehicle_chasis_no}
            {data?.vehicle_chasis_no && data?.sales_agent_name && " / "}
            {data?.sales_agent_name}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default VDForm1;
