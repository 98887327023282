import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
} from "@react-pdf/renderer";

import InterBold from "../fonts/Inter.ttf";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

Font.register({
  family: "Inter-Bold",
  src: InterBold,
});

const styles = StyleSheet.create({
  container: {
    // fontFamily: "Inter",
    height: "auto",
  },
  borderBottom: {
    borderBottom: "1px solid black",
  },
  borderRight: {
    borderRight: "1px solid black",
  },
  table: {
    display: "flex",
    gap: "1px",
    flexDirection: "row",
  },
});

const ImportantNote = ({ data }) => {
  return (
    <View style={styles.container}>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              fontSize: "9px",
              paddingLeft: "2px",
              paddingTop: "2px",
              fontFamily: "Inter-Bold",
            },
          ]}
        >
          IMPORTANT NOTE:
        </Text>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.7,
              textAlign: "center",
              fontSize: "7px",
              paddingTop: "2px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (A)
        </Text>
        <Text
          style={[
            {
              flex: 6,
              fontSize: "7px",
              paddingRight: "2px",
              paddingTop: "2px",
              // fontFamily: "Inter",
              whiteSpace: "pre-line",
            },
          ]}
        >
          Only payments evidenced by the company's official receipts are
          recognized. Please insist on official receipt(s) for all payments
          made.
        </Text>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.7,
              textAlign: "center",
              fontSize: "7px",
              paddingTop: "8px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (B)
        </Text>
        <Text
          style={[
            {
              flex: 6,
              fontSize: "7px",
              paddingRight: "2px",
              paddingTop: "8px",
              // fontFamily: "Inter",
            },
          ]}
        >
          If payment are by cheques, such cheques shall be drawn in favour of
          the company and crossed "A/C Payee Only and Not Negotiable. "All
          Payments by cheques made to the Compeny shall not be treated as having
          been fully effected until such cheques are cleared.
        </Text>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.7,
              textAlign: "center",
              fontSize: "7px",
              paddingTop: "8px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (C)
        </Text>
        <View style={{ flex: 6 }}>
          <Text
            style={[
              {
                fontSize: "7px",
                paddingRight: "2px",
                paddingTop: "8px",
                fontFamily: "Inter-Bold",
              },
            ]}
          >
            *TINTED WINDSCREEN AND GLASSES
          </Text>
          <Text
            style={[
              {
                fontSize: "7px",
                paddingRight: "2px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            I hereby agree and understand that it's the policy of the Government
            (PUSPAKOM) that all vehicles must come in clear glasses before it
            can be inspect and register with the JPJ. It's illegal to have
            tinted windscreen and glasses.
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.7,
              textAlign: "center",
              fontSize: "7px",
              paddingRight: "2px",
              paddingTop: "8px",
              // fontFamily: "Inter",
            },
          ]}
        >
          (D)
        </Text>
        <View style={{ flex: 6 }}>
          <Text
            style={[
              {
                fontSize: "7px",
                paddingRight: "2px",
                paddingTop: "8px",
                fontFamily: "Inter-Bold",
              },
            ]}
          >
            *NUMBER PLATE REGISTRATION:
          </Text>
          <Text
            style={[
              {
                fontSize: "7px",
                paddingRight: "2px",
                paddingTop: "2px",
                // fontFamily: "Inter",
              },
            ]}
          >
            The customer hereby agreed to accept whatever registration number
            that is allotted to the vehicle by the road transport department.
          </Text>
          <Text
            style={[
              {
                fontSize: "7px",
                paddingRight: "2px",
                paddingTop: "8px",
                // fontFamily: "Inter",
              },
            ]}
          >
            If the customer wish to have any special number, it is the
            customer's duty to arrange with whoever for the tender of the
            special registration number from the JPJ & provide the JPJ official
            receipt for the tender number prior to registration of the vehicle.
            The company shall no responsible for any failure to obtain the
            special number.
          </Text>
        </View>
      </View>
      <View style={[styles.table, { paddingTop: "15px" }]}>
        <Text
          style={[
            {
              flex: 0.7,
              textAlign: "center",
              fontSize: "7px",
              paddingRight: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <View style={{ flex: 6 }}>
          <Text
            style={[
              {
                fontSize: "7px",
                paddingRight: "2px",
                fontFamily: "Inter-Bold",
              },
            ]}
          >
            Requested tender no
          </Text>
        </View>
      </View>
      <View style={[styles.table, styles.borderBottom, { paddingTop: "22px" }]}>
        <Text
          style={[
            {
              flex: 0.7,
              textAlign: "center",
              fontSize: "7px",
              paddingRight: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <View style={{ flex: 6 }}>
          <Text
            style={[
              {
                fontSize: "10px",
                paddingRight: "2px",
                paddingBottom: "8px",
                fontFamily: "Inter-Bold",
                textAlign: "center",
              },
            ]}
          >
            Signed :
          </Text>
        </View>
      </View>
      <View style={[styles.table]}>
        <Text
          style={[
            {
              flex: 0.7,
              textAlign: "center",
              fontSize: "7px",
              paddingRight: "2px",
              // fontFamily: "Inter",
            },
          ]}
        ></Text>
        <View style={{ flex: 6 }}>
          <Text
            style={[
              {
                fontSize: "7px",
                paddingRight: "2px",
                paddingTop: "10px",
                paddingBottom: "23px",
                // fontFamily: "Inter",
                textAlign: "center",
              },
            ]}
          >
            {data?.sales_quotation?.date
              ? new Date(data.sales_quotation.date).toLocaleDateString()
              : ""}{" "}
            Buyer/Hirer*
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ImportantNote;
