const appConfig = {
  apiPrefix: "/api",
  authenticatedEntryPath: "/dashboard",
  otherEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: false,
}

export default appConfig
